import React from "react";
import "../CSS/ListofProducts.css";
import axios from "axios";
import { useEffect, useState } from "react";
import Select from "react-select";
import AllNav from "../Components/Navbar/AllNav";
import LoadingScreen from "./LoadingScreen";

const CostAnalysis = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [productioncost, setProductionCost] = useState("");
  const [transportcost, setTransportCost] = useState("");
  const [totalcost, setTotalcost] = useState("");
  const [markup, setMarkup] = useState("");
  const [selPricBefTra, setselPricBefTra] = useState("");
  const [selPricAftTra, setselPricAftTra] = useState("");
  const [myItem, setItem] = useState([]);
  const [selItemCode, setSelItemCode] = useState("");
  const [selItemName, setSelItemName] = useState("");
  const [myLocation, setmyLocation] = useState([]);
  const [selFromLocation, setselFromLocation] = useState("");
  const [selToLocation, setSelToLocation] = useState("");
  const [myRouteId, setmyRouteId] = useState("");
  const [isloading, setisloading] = useState(true);
  const [discount, setDiscount] = useState("");
  const [profit, setProfit] = useState("");
  const [qty, setQty] = useState("");
  const [costper1kg, setCostPer1Kg] = useState("");
  const [originalProductionCost, setoriginalProductionCost] = useState("");
  const [originalTotalCost, setoriginalTotalCost] = useState("");
  const [originalSellinPriceBef, setoriginalSellinPriceBef] = useState("");
  const [originalSellingPriceAft, setoriginalSellingPriceAft] = useState("");
  const [originalProfit, setoriginalProfit] = useState("");
  const [sizeOfBag, setSizeOfBag] = useState("");
  const [productionCostOf1Kg, setProductionCostOf1Bag] = useState("");
  const [originalSizeOfBag, setOriginalSizeOfBag] = useState("");

  useEffect(() => {
    LoadLocation();
    LoadItems();
  }, []);

  function numberWithCommas(value) {
    let fixedNumber = parseFloat(value).toFixed(2);
    return fixedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const LoadItems = () => {
    axios
      .get(`${BASEURL}/LoadItems`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setisloading(false);
        setItem(response.data);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching Item details:", error);
      });
  };

  const LoadLocation = () => {
    axios
      .get(`${BASEURL}/LoadLocation`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setmyLocation(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Location details:", error);
      });
  };

  const fetchProductiondetailsinTransport = (productCode) => {
    axios
      .get(
        `${BASEURL}/fetchProductiondetailsinTransport?DERIVEDITEMCODE=${productCode}`,
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        console.log(response?.data);
        setMarkup(response?.data[0]?.MARKUPPERC);
        setOriginalSizeOfBag(response?.data[0]?.DERIVEDKG);
        setProductionCost(response?.data[0]?.DERIVEDTOTALKG);
        setCostPer1Kg(
          Number(response?.data[0]?.DERIVEDTOTALKG) /
            Number(response?.data[0]?.DERIVEDKG)
        );
        setProductionCostOf1Bag(
          Number(response?.data[0]?.DERIVEDTOTALKG) /
            Number(response?.data[0]?.DERIVEDKG)
        );
        setoriginalProductionCost(response?.data[0]?.DERIVEDTOTALKG);
        setselPricBefTra(response?.data[0]?.DERIVEDSELLINGPRICE);
        setoriginalSellinPriceBef(response?.data[0]?.DERIVEDSELLINGPRICE);
        setTransportCost(0);
        setTotalcost(response?.data[0]?.DERIVEDTOTALKG);
        setoriginalTotalCost(response?.data[0]?.DERIVEDTOTALKG);
        setselPricAftTra(response?.data[0]?.DERIVEDSELLINGPRICE);
        setoriginalSellingPriceAft(response?.data[0]?.DERIVEDSELLINGPRICE);
        setProfit(
          Number(response?.data[0]?.DERIVEDSELLINGPRICE) -
            Number(response?.data[0]?.DERIVEDTOTALKG)
        );
        setoriginalProfit(
          Number(response?.data[0]?.DERIVEDSELLINGPRICE) -
            Number(response?.data[0]?.DERIVEDTOTALKG)
        );
        setQty(response?.data[0]?.DERIVEDITEMQTY);
      })
      .catch((error) => {
        console.error("Error fetching Location details:", error);
      });
  };

  const getRouteId = (e) => {
    setisloading(true);
    if (selFromLocation === "") {
      alert("Please select the from location first");
    } else if (selItemName === "") {
      alert("Please select an Item first");
    } else {
      axios
        .post(
          `${BASEURL}/LoadRoutes`,
          {
            fromloc: selFromLocation,
            toloc: e.value,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          if (response.data.err) {
            alert(response.data.err);
            setselFromLocation("");
            setSelToLocation("");
          } else {
            setmyRouteId(response.data[0].ROUTEID);
            axios
              .post(
                `${BASEURL}/Listofproducts`,
                {
                  ItemCode: selItemCode,
                  RouteId: response.data[0].ROUTEID,
                },
                {
                  headers: {
                    "auth-token": authToken,
                  },
                }
              )
              .then((response) => {
                setProductionCost(response.data[0].COSTPOSTPRODUCTION);
                setTransportCost(response.data[0].TRANSPORTCOSTPERBAG);
                setTotalcost(response.data[0].COSTPOSTPRODUCTIONANDTRANSPORT);
                setMarkup(response.data[0].MARKUPPERC);
                setselPricBefTra(
                  response.data[0].ITEMSELLINGPRICEBEDFORETRANSPORT
                );
                setselPricAftTra(
                  response.data[0].ITEMSELLINGPRICEAFTERTRANSPORT
                );
                setProfit(
                  Number(response.data[0].ITEMSELLINGPRICEAFTERTRANSPORT) -
                    Number(response.data[0].COSTPOSTPRODUCTIONANDTRANSPORT)
                );
              })
              .catch((error) => {
                console.error("Error fetching List Of Products:", error);
              });
          }
          setisloading(false);
        })
        .catch((error) => {
          setisloading(false);
          console.error("Error fetching Route Details:", error);
        });
    }
  };

  const handleItemName = (e) => {
    const selectedValue = e;
    const selectedOption = myItem.find((obj) => obj.MAINNAME === selectedValue);
    if (selectedValue === "") {
      alert("Please select proper Item Name");
    } else if (selectedOption === "") {
      alert("Please select proper Item Name");
    } else {
      setSelItemName(selectedValue);
      setSelItemCode(selectedOption.ITEMCODE);
      fetchProductiondetailsinTransport(selectedOption.ITEMCODE);
    }
  };

  const styleforselect = {
    control: (provided, state) => ({
      ...provided,

      borderBottom: "1px solid #ccc",
      fontSize: "16px",
    }),
    option: (provided, state) => ({
      ...provided,
    }),
  };

  const handleProductionCost = (e) => {
    let size = 0;
    if (sizeOfBag) {
      size = parseFloat(sizeOfBag.replace(/[^0-9.]/g, ""));
    } else {
      size = originalSizeOfBag;
    }

    if (Number(size) !== 0) {
      setCostPer1Kg(Number(e) / Number(size));
    }

    setTotalcost(Number(e) + Number(transportcost));
    setselPricBefTra(Number(e) + (Number(e) * Number(markup)) / 100);
    setselPricAftTra(
      Number(e) + (Number(e) * Number(markup)) / 100 + Number(transportcost)
    );
  };

  const handleTransportCost = (e) => {
    setTotalcost(Number(e) + Number(productioncost));
    setselPricAftTra(Number(e) + Number(selPricBefTra));
  };

  const handleCostPerKg = (e) => {
    let size = 0;
    if (sizeOfBag) {
      size = parseFloat(sizeOfBag.replace(/[^0-9.]/g, ""));
    } else {
      size = originalSizeOfBag;
    }
    const totalCostPerBale = Number(e) * Number(size);
    setTotalcost(totalCostPerBale);
    setProductionCost(Number(totalCostPerBale) - Number(transportcost));
    setselPricBefTra(
      Number(totalCostPerBale) -
        Number(transportcost) +
        ((Number(totalCostPerBale) - Number(transportcost)) * markup) / 100
    );
    setselPricAftTra(
      Number(totalCostPerBale) +
        (Number(totalCostPerBale) * Number(markup)) / 100
    );
  };

  const handleTotalCost = (e) => {
    let size = 0;
    if (sizeOfBag) {
      size = parseFloat(sizeOfBag.replace(/[^0-9.]/g, ""));
    } else {
      size = originalSizeOfBag;
    }

    const productionCost = Number(e) - Number(transportcost);
    if (Number(size) !== 0) {
      setCostPer1Kg(Number(productionCost) / Number(size));
    }
    setProductionCost(Number(e) - Number(transportcost));
    setselPricBefTra(
      Number(e) -
        Number(transportcost) +
        ((Number(e) - Number(transportcost)) * markup) / 100
    );
    setselPricAftTra(Number(e) + (Number(e) * Number(markup)) / 100);
  };

  const handleMarkup = (e) => {
    setselPricBefTra(
      Number(productioncost) + Number(productioncost) * (Number(e) / 100)
    );
    setselPricAftTra(Number(totalcost) + Number(totalcost) * (Number(e) / 100));
  };

  const handleDiscount = (value) => {
    setProfit(
      parseFloat(selPricAftTra) * ((100 - value) / 100) - parseFloat(totalcost)
    );
  };

  const handleQuantity = (quantity) => {
    setTotalcost(Number(quantity) * Number(originalTotalCost));
    setselPricBefTra(Number(quantity) * Number(originalSellinPriceBef));
    setselPricAftTra(Number(quantity) * Number(originalSellingPriceAft));
    setProfit(Number(quantity) * Number(originalProfit));
  };

  const handleSizeOfBag = (value) => {
    let size = 0;
    if (value != originalSizeOfBag) {
      size = parseFloat(value.replace(/[^0-9.]/g, ""));
    } else {
      size = value;
    }
    setProductionCost(Number(productionCostOf1Kg) * Number(size));
    let prod = Number(productionCostOf1Kg) * Number(size);
    setTotalcost(Number(prod) + Number(transportcost));
    let total = Number(prod) + Number(transportcost);
    setoriginalTotalCost(Number(prod) + Number(transportcost));
    setselPricBefTra(Number(prod) + (Number(prod) * Number(markup)) / 100);
    setoriginalSellinPriceBef(
      Number(prod) + (Number(prod) * Number(markup)) / 100
    );
    setselPricAftTra(
      Number(prod) +
        (Number(prod) * Number(markup)) / 100 +
        Number(transportcost)
    );
    let selPrice =
      Number(prod) +
      (Number(prod) * Number(markup)) / 100 +
      Number(transportcost);
    setoriginalSellingPriceAft(
      Number(prod) +
        (Number(prod) * Number(markup)) / 100 +
        Number(transportcost)
    );
    setProfit(selPrice - total);
    setoriginalProfit(selPrice - total);
  };

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <AllNav />

      <h1 style={{ textAlign: "center" }}>Cost Analysis</h1>

      <div>
        <section className="main__sec__Align">
          <main className=" new__first__div">
            <aside>
              <p>Product Name&nbsp;</p>
              <div className="select__name">
                <Select
                  className=""
                  onChange={(selectedOption) => {
                    if (myRouteId) {
                      window.location.reload();
                    } else {
                      handleItemName(selectedOption.value);
                    }
                  }}
                  value={
                    selItemName
                      ? { label: selItemName, value: selItemName }
                      : { label: "", value: "" }
                  }
                  name="ItemName"
                  id="itemname"
                  styles={styleforselect}
                  options={[
                    { value: "", label: "" },
                    ...myItem.map((item) => ({
                      value: item.MAINNAME,
                      label: item.MAINNAME,
                    })),
                  ]}
                />
              </div>
            </aside>
            <aside>
              <p>From:&nbsp;</p>
              <div className="select__id">
                <Select
                  onChange={(e) => {
                    if (myRouteId) {
                      window.location.reload();
                    } else {
                      if (selItemCode === "") {
                        alert("Please select an Item first");
                        setselFromLocation("");
                      } else if (e.value === "") {
                        alert("Please select proper from location");
                        setselFromLocation("");
                      } else {
                        if (selToLocation) {
                          setSelToLocation("");
                          setmyRouteId("");
                          setselFromLocation(e.value);
                        } else {
                          setselFromLocation(e.value);
                        }
                      }
                    }
                  }}
                  value={
                    selFromLocation
                      ? { label: selFromLocation, value: selFromLocation }
                      : { label: "", value: "" }
                  }
                  styles={styleforselect}
                  options={[
                    { value: "", label: "" },
                    ...myLocation.map((item) => ({
                      value: item.CITYNAME,
                      label: item.CITYNAME,
                    })),
                  ]}
                />
              </div>
            </aside>
            <aside className="to">
              <p>To &nbsp;</p>
              <div className="select__id">
                <Select
                  onChange={(e) => {
                    if (myRouteId) {
                      window.location.reload();
                    } else {
                      if (selItemCode === "") {
                        alert("Please select an Item first");
                        setSelToLocation("");
                      } else if (!selFromLocation) {
                        alert("Please select From Location first");
                        setSelToLocation("");
                      } else if (e.value === "") {
                        alert("Please select proper to location");
                        setSelToLocation("");
                      } else {
                        if (myRouteId) {
                          setmyRouteId("");
                          setSelToLocation(e.value);
                          getRouteId(e);
                        } else {
                          setSelToLocation(e.value);
                          getRouteId(e);
                        }
                      }
                    }
                  }}
                  value={
                    selToLocation
                      ? { label: selToLocation, value: selToLocation }
                      : { label: "", value: "" }
                  }
                  styles={styleforselect}
                  options={[
                    { value: "", label: "" },
                    ...myLocation.map((item) => ({
                      value: item.CITYNAME,
                      label: item.CITYNAME,
                    })),
                  ]}
                />
              </div>
            </aside>
            <aside className="quantity__input">
              <p>Route Id &nbsp;</p>
              <div>
                <input
                  style={{
                    color: "red",
                    fontWeight: 600,
                    pointerEvents: "none",
                    width: "100px",
                    height: "30px",
                  }}
                  type="number"
                  value={myRouteId}
                  readOnly
                />
              </div>
            </aside>
            <button
              className="Save_import"
              style={{ marginTop: "3px" }}
              onClick={() => {
                window.location.reload();
              }}
            >
              New
            </button>
          </main>
        </section>

        <div className="style">
          <div>
            <div>
              <div className="costing__analisis">
                <div>
                  <label>Production Cost/Bale </label>
                </div>
                <div style={{ marginLeft: "75px" }}>
                  <b>
                    <input
                      style={{
                        border: "1px solid #ccc",
                        height: "30px",
                        color: "red",
                        fontWeight: 600,
                        textAlign: "right",
                      }}
                      type="number"
                      onChange={(e) => {
                        if (Number(e.target.value) < 0) {
                          alert("production cost Cannot be negative");
                        } else {
                          setProductionCost(e.target.value);
                          handleProductionCost(e.target.value);
                        }
                      }}
                      value={
                        productioncost
                          ? parseFloat(productioncost).toFixed(2)
                          : ""
                      }
                    />
                  </b>
                </div>
              </div>
            </div>
            <div>
              <div className="costing__analisis">
                <div>
                  <label>Size Of Bale (in KG)</label>
                </div>
                <div style={{ marginLeft: "90px" }}>
                  <b>
                    <Select
                      onChange={(e) => {
                        if (!selItemName) {
                          alert("Kindly Supply Product Name");
                        } else {
                          if (e.value == "") {
                            setSizeOfBag("");
                            handleSizeOfBag(originalSizeOfBag);
                          } else {
                            setSizeOfBag(e.value);
                            handleSizeOfBag(e.value);
                          }
                        }
                      }}
                      value={
                        sizeOfBag
                          ? { label: sizeOfBag, value: sizeOfBag }
                          : { label: "Select Bale Size", value: "" }
                      }
                      styles={styleforselect}
                      options={[
                        { value: "", label: "Select Bale Size" },
                        { value: "24 KG", label: "24 KG" },
                        { value: "25 KG", label: "25 KG" },
                        { value: "50 KG", label: "50 KG" },
                      ]}
                    />
                  </b>
                </div>
              </div>
            </div>
            <div>
              <div className="costing__analisis">
                <div>
                  <label>Bales Required</label>
                </div>
                <div style={{ marginLeft: "128px" }}>
                  <b>
                    <input
                      style={{
                        border: "1px solid #ccc",
                        height: "30px",
                        color: "red",
                        fontWeight: 600,
                        textAlign: "right",
                      }}
                      type="number"
                      value={qty}
                      onChange={(e) => {
                        if (Number(e.target.value) <= 0) {
                          alert("Quantity  Cannot be less than or equal to 0");
                        } else {
                          setQty(e.target.value);
                          handleQuantity(e.target.value);
                        }
                      }}
                    />
                  </b>
                </div>
              </div>
            </div>

            <div>
              <div className="costing__analisis">
                <div>
                  <label>Transport Cost </label>
                </div>
                <div style={{ marginLeft: "132px" }}>
                  <b>
                    <input
                      style={{
                        border: "1px solid #ccc",
                        height: "30px",
                        color: "red",
                        fontWeight: 600,
                        textAlign: "right",
                      }}
                      type="number"
                      value={
                        transportcost
                          ? parseFloat(transportcost).toFixed(2)
                          : "0"
                      }
                      onChange={(e) => {
                        if (Number(e.target.value) < 0) {
                          alert("transport cost Cannot be negative");
                        } else {
                          setTransportCost(e.target.value);
                          handleTransportCost(e.target.value);
                        }
                      }}
                    />
                  </b>
                </div>
              </div>
            </div>

            <div>
              <div className="costing__analisis">
                <div>
                  <label>Original Size Of Bale (KGs) </label>
                </div>
                <div style={{ marginLeft: "35px" }}>
                  <b>
                    <input
                      style={{
                        border: "1px solid #ccc",
                        height: "30px",
                        color: "red",
                        fontWeight: 600,
                        textAlign: "right",
                      }}
                      type="text"
                      readOnly
                      // onChange={(e) => {
                      //   if (Number(e.target.value) < 0) {
                      //     alert("production cost Cannot be negative");
                      //   } else {
                      //     setProductionCost(e.target.value);
                      //     handleProductionCost(e.target.value);
                      //   }
                      // }}
                      value={
                        originalSizeOfBag
                          ? parseFloat(originalSizeOfBag).toFixed(2)
                          : ""
                      }
                    />
                  </b>
                </div>
              </div>
            </div>

            {/* Cost / KG  */}

            <div>
              <div className="costing__analisis">
                <div>
                  <label>Cost for 1 KG</label>
                </div>
                <div style={{ marginLeft: "145px" }}>
                  <b>
                    <input
                      style={{
                        border: "1px solid #ccc",
                        height: "30px",
                        color: "red",
                        fontWeight: 600,
                        textAlign: "right",
                      }}
                      type="number"
                      value={
                        costper1kg ? parseFloat(costper1kg).toFixed(2) : ""
                      }
                      onChange={(e) => {
                        if (Number(e.target.value) < 0) {
                          alert("Cost Per KG Cannot be negative");
                        } else {
                          setCostPer1Kg(e.target.value);
                          handleCostPerKg(e.target.value);
                        }
                      }}
                    />
                  </b>
                </div>
              </div>
            </div>

            <div>
              <div className="costing__analisis">
                <div>
                  <label>Total Cost for Bales Required </label>
                </div>
                <div style={{ marginLeft: "15px" }}>
                  <b>
                    <input
                      style={{
                        border: "1px solid #ccc",
                        height: "30px",
                        color: "red",
                        fontWeight: 600,
                        textAlign: "right",
                      }}
                      type="number"
                      value={totalcost ? parseFloat(totalcost).toFixed(2) : ""}
                      onChange={(e) => {
                        if (Number(e.target.value) < 0) {
                          alert("total cost Cannot be negative");
                        } else {
                          setTotalcost(e.target.value);
                          handleTotalCost(e.target.value);
                        }
                      }}
                    />
                  </b>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div className="costing__analisis">
                <div>
                  <label>Markup %</label>
                </div>
                <div style={{ marginLeft: "167px" }}>
                  <b>
                    <input
                      style={{
                        border: "1px solid #ccc",
                        height: "30px",
                        color: "red",
                        fontWeight: 600,
                        textAlign: "right",
                      }}
                      type="number"
                      value={markup}
                      onChange={(e) => {
                        if (Number(e.target.value) < 0) {
                          alert("Markup % Cannot be negative");
                        } else {
                          setMarkup(e.target.value);
                          handleMarkup(e.target.value);
                        }
                      }}
                    />
                  </b>
                </div>
              </div>
            </div>
            <div>
              <div className="costing__analisis">
                <div>
                  <label>Selling Price Before Transport</label>
                </div>
                <div style={{ marginLeft: "10px" }}>
                  <input
                    style={{
                      pointerEvents: "none",
                      border: "1px solid #ccc",
                      height: "30px",
                      color: "red",
                      fontWeight: 600,
                      textAlign: "right",
                    }}
                    type="number"
                    value={
                      selPricBefTra ? parseFloat(selPricBefTra).toFixed(2) : ""
                    }
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="costing__analisis">
                <div>
                  <label>Selling Price After Transport</label>
                </div>
                <div style={{ marginLeft: "22px" }}>
                  <b>
                    <input
                      style={{
                        pointerEvents: "none",
                        border: "1px solid #ccc",
                        height: "30px",
                        color: "red",
                        fontWeight: 600,
                        textAlign: "right",
                      }}
                      type="number"
                      value={
                        selPricAftTra
                          ? parseFloat(selPricAftTra).toFixed(2)
                          : ""
                      }
                      readOnly
                    />
                  </b>
                </div>
              </div>
            </div>

            <div>
              <div className="costing__analisis">
                <div>
                  <label>Discount %</label>
                </div>
                <div style={{ marginLeft: "158px" }}>
                  <b>
                    <input
                      style={{
                        border: "1px solid #ccc",
                        height: "30px",
                        color: "red",
                        fontWeight: 600,
                        textAlign: "right",
                      }}
                      type="number"
                      value={discount}
                      onChange={(e) => {
                        if (Number(e.target.value) < 0) {
                          alert("Discount % Cannot be negative");
                        } else {
                          setDiscount(e.target.value);
                          handleDiscount(e.target.value);
                        }
                      }}
                    />
                  </b>
                </div>
              </div>
            </div>

            <div>
              <div className="costing__analisis">
                <div>
                  <label>Profit on Bales required</label>
                </div>
                <div style={{ marginLeft: "56px" }}>
                  <b>
                    <input
                      style={{
                        pointerEvents: "none",
                        border: "1px solid #ccc",
                        height: "30px",
                        color: "red",
                        fontWeight: 600,
                        textAlign: "right",
                      }}
                      type="number"
                      value={profit ? parseFloat(profit).toFixed(2) : ""}
                      readOnly
                    />
                  </b>
                </div>

                {profit && profit < 0 && discount && (
                  <div
                    className="alert alert-danger"
                    role="alert"
                    style={{ paddingLeft: "10px" }}
                  >
                    Discount provided will result in a Loss !!
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CostAnalysis;
