import React, { useEffect, useState } from "react";
import { Link, useFetcher } from "react-router-dom";
import "../CSS/ProductionModule.css";
import axios from "axios";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../CSS/ImportCosting.css";
import AllNav from "../Components/Navbar/AllNav";
import LoadingScreen from "./LoadingScreen";
import { costingno } from "../Redux/Action/costingno";
import moment from "moment";

const ImportCosting = () => {
  const state = useSelector((state) => state.changeTheState.user);
  const costingnoreport = useSelector((state) => state?.costingnoReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const username = state.emailId;
  const [date, setDate] = useState(new Date().toISOString());
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [listofcostingno, setlistofcostingno] = useState([]);
  const [selcostingno, setselcostingno] = useState("");
  const [importcostingno, setimportcostingno] = useState("");
  const [rawmaterial, setRawMaterial] = useState([]);
  const [selItemCode, setselItemCode] = useState("");
  const [selitemname, setselItemName] = useState("");
  const [costsheetItem, setCostSheetItem] = useState([]);
  const [selcostitem, setselcostitem] = useState("");
  const [costpermt, setcostpermt] = useState("");
  const [othercharges, setOthercharges] = useState([]);
  const [selothercharges, setselothercharges] = useState("");
  const [otherchargescost, setOtherChargesCost] = useState("");
  const [quantityinmt, setquantityinmt] = useState("");
  const [exchangerate, setexchangerate] = useState(null);
  const [amountinUSD, setamountinUSD] = useState("");
  const [amountinKSH, setamountinKSH] = useState("");
  const [amountinclvat, setamountinclvat] = useState("");
  const [tabledata, setTabledata] = useState([]);
  const [ShowTable, setShowTable] = useState(false);
  const [ShowTable1, setShowTable1] = useState(false);
  let totalcostexclvat = 0;
  let totalcostinclvat = 0;
  let finalothercharges = 0;
  let totalcostexclvat1 = 0;
  let totalcostinclvat1 = 0;
  let finalothercharges1 = 0;
  const [totalcostexclvatother, settotalcostexclvatother] = useState(0);
  const [totalcostinclvatother, settotalcostinclvatother] = useState(0);
  const [costpermtexclvat, setcostpermtexclvat] = useState(0);
  const [costperkiloexclvat, setcostperkiloexclvat] = useState(0);
  const [costper90kgsexclvat, setcostper90kgsexclvat] = useState(0);
  const [costpermtinclvat, setcostpermtinclvat] = useState(0);
  const [costperkiloinclvat, setcostperkiloinclvat] = useState(0);
  const [costper90kgsinclvat, setcostper90kgsinclvat] = useState(0);
  const [tabledata1, setTabledata1] = useState([]);
  const [costpermtexclvatother, setcostpermtexclvatother] = useState(0);
  const [costperkiloexclvatother, setcostperkiloexclvatother] = useState(0);
  const [costper90kgsexclvatother, setcostper90kgsexclvatother] = useState(0);
  const [costpermtinclvatother, setcostpermtinclvatother] = useState(0);
  const [costperkiloinclvatother, setcostperkiloinclvatother] = useState(0);
  const [costper90kgsinclvatother, setcostper90kgsinclvatother] = useState(0);
  const [historicdata, setHistoricData] = useState([]);
  const [historicdata1, setHistoricData1] = useState([]);
  const [index, setIndex] = useState(null);
  const [index1, setIndex1] = useState(null);
  const [index2, setIndex2] = useState(null);
  const [index3, setIndex3] = useState(null);
  const [flag, setflag] = useState(0);
  const [flag1, setflag1] = useState(0);
  const [flag2, setflag2] = useState(0);
  const [flag3, setflag3] = useState(0);
  const [updateddata, setUpdateddata] = useState([]);
  const [updateddata1, setupdateddata1] = useState([]);
  let updatedexcl = 0;
  let updatedincl = 0;
  let finalother = 0;
  const [selhistoricraw, setselhistoricraw] = useState("");
  const [narration, setNarration] = useState("");
  const [isloading, setisloading] = useState(true);
  const [loading, setloading] = useState(false);
  const [hist, setHist] = useState(false);
  const [wheatcost, setWheatCost] = useState("");
  const [vesselName, setVesselName] = useState("");
  const [supplierCode, setSupplierCode] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [listOfSuppliers, setListOfSuppliers] = useState([]);
  const [initialexch, setInitialExch] = useState(0);

  useEffect(() => {
    getImportCostingNo();
    getRawMaterials();
    getCostSheetItem();
    getSupplierList();
    getListOfOtherCharges();
  }, []);

  useEffect(() => {
    const fetchExch = async () => {
      try {
        const response = await axios.get(
          "https://api.exchangeratesapi.net/v1/exchange-rates/latest?access_key=VA4WSf86aokaOVBA&base=USD"
        );
        // Extract USD rate from the response
        const usd = response.data.rates.KES;
        setInitialExch(parseFloat(usd).toFixed(2));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchExch();
  }, []);

  tabledata.forEach((item) => {
    totalcostexclvat += parseFloat(item.amountExclVAT);
    totalcostinclvat += parseFloat(item.amountInclVAT);
  });

  historicdata.forEach((item) => {
    totalcostexclvat += parseFloat(item.OT_COSTING_SHEET_COST_EXC_VAT_KSH);
    totalcostinclvat += parseFloat(item.OT_COSTING_SHEET_CHARGE_COST_TOTAL);
    totalcostexclvat1 += parseFloat(item.OT_COSTING_SHEET_COST_EXC_VAT_KSH);
    totalcostinclvat1 += parseFloat(item.OT_COSTING_SHEET_CHARGE_COST_TOTAL);
  });

  updateddata.forEach((item) => {
    updatedexcl += parseFloat(item.OT_COSTING_SHEET_COST_EXC_VAT_KSH);
    updatedincl += parseFloat(item.OT_COSTING_SHEET_CHARGE_COST_TOTAL);
  });

  historicdata1.forEach((item) => {
    finalothercharges += parseFloat(item.OTHERCHARGEAMOUNT);
    finalothercharges1 += parseFloat(item.OTHERCHARGEAMOUNT);
  });

  updateddata1.forEach((item) => {
    finalother += parseFloat(item.OTHERCHARGEAMOUNT);
  });

  tabledata1.forEach((item) => {
    finalothercharges += parseFloat(item.cost);
  });

  const handleRowClickk = (rowData, index) => {
    setflag(1);
    setIndex(index);
    setselcostitem(rowData.description);
    setcostpermt(rowData.cost);
    setamountinUSD(rowData.amountUSD);
    setexchangerate(rowData.exch);
    setamountinKSH(rowData.amountExclVAT);
    setNarration(rowData.narration);
  };

  const handleRowClick = (rowData, index) => {
    setflag1(1);
    setIndex1(index);
    setselcostitem(rowData.OT_COSTING_SHEET_CHARGE_CODE);
    setcostpermt(rowData.OT_COSTING_SHEET_VAT_COST_PER_UNIT);
    setamountinUSD(rowData.OT_COSTING_SHEET_CHARGE_RATE_USD);
    setexchangerate(rowData.OT_COSTING_SHEET_CHARGE_EXCH_RATE);
    setamountinKSH(rowData.OT_COSTING_SHEET_COST_EXC_VAT_KSH);
    setNarration(rowData.OT_COSTING_SHEET_DESCRIPTION);
    setamountinclvat(rowData.OT_COSTING_SHEET_CHARGE_COST_TOTAL);
  };

  const handleOtherChargesRow = (rowData, index) => {
    setflag2(1);
    setIndex2(index);
    setselothercharges(rowData.OTHERCHARGE);
    setOtherChargesCost(rowData.OTHERCHARGEAMOUNT);
  };

  const handleOtherChargesRow1 = (rowData, index) => {
    setflag3(1);
    setIndex3(index);
    setselothercharges(rowData.description);
    setOtherChargesCost(rowData.cost);
  };

  const handleExclInclVAT = (a, b) => {
    let excl = 0;
    let incl = 0;
    // if (
    //   wheatcost &&
    //   (selcostitem == "Import Duty" || selcostitem == "Marine Cover")
    // ) {
    //   excl = a / Number(wheatcost);
    //   incl = b / Number(wheatcost);
    // } else {
    excl = a / Number(quantityinmt);
    incl = b / Number(quantityinmt);
    // }
    setcostpermtexclvat(Number(excl).toFixed(2));
    setcostperkiloexclvat(Number(excl / 1000).toFixed(2));
    setcostper90kgsexclvat(Number((excl / 1000) * 90).toFixed(2));
    setcostpermtinclvat(Number(incl).toFixed(2));
    setcostperkiloinclvat(Number(incl / 1000).toFixed(2));
    setcostper90kgsinclvat(Number((incl / 1000) * 90).toFixed(2));
  };

  const handleOtherCharges = (a) => {
    let excl = 0;
    let incl = 0;
    if (updatedexcl > 0 && updatedincl > 0) {
      excl = Number(updatedexcl) + Number(a);
      incl = Number(updatedincl) + Number(a);
    } else {
      excl = Number(totalcostexclvat) + Number(a);
      incl = Number(totalcostinclvat) + Number(a);
    }
    settotalcostexclvatother(Number(excl).toFixed(2));
    settotalcostinclvatother(Number(incl).toFixed(2));
    let exclother = 0;
    let inclother = 0;
    // if (
    //   wheatcost &&
    //   (selcostitem == "Import Duty" || selcostitem == "Marine Cover")
    // ) {
    //   exclother = Number(excl) / Number(wheatcost);
    //   inclother = Number(incl) / Number(wheatcost);
    // } else {
    exclother = Number(excl) / Number(quantityinmt);
    inclother = Number(incl) / Number(quantityinmt);
    // }
    setcostpermtexclvatother(Number(exclother).toFixed(2));
    setcostperkiloexclvatother(Number(exclother / 1000).toFixed(2));
    setcostper90kgsexclvatother(Number((exclother / 1000) * 90).toFixed(2));
    setcostpermtinclvatother(Number(inclother).toFixed(2));
    setcostperkiloinclvatother(Number(inclother / 1000).toFixed(2));
    setcostper90kgsinclvatother(Number((inclother / 1000) * 90).toFixed(2));
  };

  const handleOtherChargesModified = (a, b, c) => {
    const excl = Number(b) + Number(a);
    const incl = Number(c) + Number(a);
    settotalcostexclvatother(Number(excl).toFixed(2));
    settotalcostinclvatother(Number(incl).toFixed(2));
    let exclother = 0;
    let inclother = 0;
    // if (
    //   wheatcost &&
    //   (selcostitem == "Import Duty" || selcostitem == "Marine Cover")
    // ) {
    //   exclother = Number(excl) / Number(wheatcost);
    //   inclother = Number(incl) / Number(wheatcost);
    // } else {
    exclother = Number(excl) / Number(quantityinmt);
    inclother = Number(incl) / Number(quantityinmt);
    // }
    setcostpermtexclvatother(Number(exclother).toFixed(2));
    setcostperkiloexclvatother(Number(exclother / 1000).toFixed(2));
    setcostper90kgsexclvatother(Number((exclother / 1000) * 90).toFixed(2));
    setcostpermtinclvatother(Number(inclother).toFixed(2));
    setcostperkiloinclvatother(Number(inclother / 1000).toFixed(2));
    setcostper90kgsinclvatother(Number((inclother / 1000) * 90).toFixed(2));
  };

  function numberWithCommas(value) {
    let fixedNumber = parseFloat(value).toFixed(2);
    return fixedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const getListOfImportCostingNo = (value) => {
    setisloading(true);
    axios
      .post(
        `${BASEURL}/ListOfImportCostingNo`,
        {
          itemcode: value,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setisloading(false);
        setlistofcostingno(response.data);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching List Of Import Costing No:", error);
      });
  };

  const getImportCostingNo = () => {
    axios
      .get(`${BASEURL}/ImportCostingNo`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setimportcostingno(response.data[0].LATESTIMPORTCOSTING);
      })
      .catch((error) => {
        console.error("Error fetching Import Costing No:", error);
      });
  };

  const getRawMaterials = () => {
    axios
      .get(`${BASEURL}/ListOfItems`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setRawMaterial(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Raw Material:", error);
      });
  };

  const getCostSheetItem = () => {
    axios
      .get(`${BASEURL}/ListOfCostItems`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setCostSheetItem(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Raw Material:", error);
      });
  };

  const getSupplierList = () => {
    axios
      .get(`${BASEURL}/getSupplierList`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setListOfSuppliers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Raw Material:", error);
      });
  };

  const getListOfOtherCharges = () => {
    axios
      .get(`${BASEURL}/ListOfOtherCharges`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setisloading(false);
        setOthercharges(response.data);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching Other charges:", error);
      });
  };

  useEffect(() => {
    if (costingnoreport !== "" && listOfSuppliers.length > 0) {
      getHistoricCosting(costingnoreport);
      getHistoricCosting1(costingnoreport);
      dispatch(costingno(""));
    }
  }, [costingnoreport, listOfSuppliers]);

  const handleItemName = (e) => {
    const selectedValue = e;
    const selectedOption = rawmaterial.find(
      (obj) => obj.ITEMDESCRIPTION === selectedValue
    );
    if (selectedValue === "") {
      setselItemName("");
      setselItemCode("");
    } else if (selectedOption === "") {
      setselItemCode("");
      setselItemName("");
    } else {
      setselItemName(selectedValue);
      setselItemCode(selectedOption.ITEMCODE);
    }
  };

  const handleItemNamehistoric = (e) => {
    const selectedValue = e;
    const selectedOption = rawmaterial.find(
      (obj) => obj.ITEMDESCRIPTION === selectedValue
    );
    getListOfImportCostingNo(selectedOption.ITEMCODE);
  };

  const handleItemCode = (e) => {
    const selectedValue = e;
    const selectedOption = rawmaterial.find(
      (obj) => obj.ITEMCODE === selectedValue
    );
    if (selectedValue === "") {
      setselItemName("");
      setselItemCode("");
    } else if (selectedOption === "") {
      setselItemName("");
      setselItemCode("");
    } else {
      setselItemCode(selectedValue);
      setselItemName(selectedOption.ITEMDESCRIPTION);
    }
  };

  // Supplier Details ***************************************************

  const handleSupplierCode = (e) => {
    console.log(e);
    console.log(listOfSuppliers);
    const selectedValue = e;
    const selectedOption = listOfSuppliers.find(
      (obj) => obj.VENDORCODE === selectedValue
    );
    if (selectedValue === "") {
      setSupplierName("");
      setSupplierCode("");
    } else if (selectedOption === "") {
      setSupplierName("");
      setSupplierCode("");
    } else {
      setSupplierCode(selectedValue);
      setSupplierName(selectedOption.VENDNAME);
    }
  };

  const handleSupplierName = (e) => {
    const selectedValue = e;
    const selectedOption = listOfSuppliers.find(
      (obj) => obj.VENDNAME === selectedValue
    );
    if (selectedValue === "") {
      setSupplierName("");
      setSupplierCode("");
    } else if (selectedOption === "") {
      setSupplierName("");
      setSupplierCode("");
    } else {
      setSupplierName(selectedValue);
      setSupplierCode(selectedOption.VENDORCODE);
    }
  };

  // *******************************************************************************************
  const handleOptionChange = (selectedOption) => {
    setselcostitem(selectedOption.value);
    // setexchangerate(140);
    setexchangerate(initialexch);
    const selected = costsheetItem.find(
      (item) => item.NONSTOCKITEM === selectedOption.value
    );
    if (selected) {
      setcostpermt(selected.CHARGE);
    } else {
      setcostpermt("");
    }
    const charge = selected.CHARGE;
    let amount = 0;
    if (
      wheatcost &&
      (selectedOption.value == "Import Duty" ||
        selectedOption.value == "Marine Cover")
    ) {
      amount = charge * Number(wheatcost);
    } else {
      amount = charge * Number(quantityinmt);
    }

    setamountinUSD(amount);
    setamountinKSH(amount * initialexch);
    setamountinclvat(amount * initialexch);
  };

  const handleCharge = (value) => {
    const charge = value;
    let amount = 0;
    if (
      wheatcost &&
      (selcostitem == "Import Duty" || selcostitem == "Marine Cover")
    ) {
      amount = charge * Number(wheatcost);
    } else {
      amount = charge * Number(quantityinmt);
    }
    setamountinUSD(amount.toFixed(2));
    setamountinKSH((amount * exchangerate).toFixed(2));
    setamountinclvat((amount * exchangerate).toFixed(2));
  };

  const handleOptionChangeOther = (selectedOption) => {
    setselothercharges(selectedOption.value);
    const selected = othercharges.find(
      (item) => item.NONSTOCKITEM === selectedOption.value
    );
    if (selected) {
      setOtherChargesCost(selected.CHARGE);
    } else {
      setOtherChargesCost("");
    }
  };

  const handleamtlclcurr = (value) => {
    if (value === 1) {
      setamountinUSD("0.0");
      setexchangerate(1);
      if (
        wheatcost &&
        (selcostitem == "Import Duty" || selcostitem == "Marine Cover")
      ) {
        setamountinKSH(parseFloat(costpermt) * parseFloat(wheatcost));
        setamountinclvat(parseFloat(costpermt) * parseFloat(wheatcost));
      } else {
        setamountinKSH(parseFloat(costpermt) * parseFloat(quantityinmt));
        setamountinclvat(parseFloat(costpermt) * parseFloat(quantityinmt));
      }
    } else {
      if (
        wheatcost &&
        (selcostitem == "Import Duty" || selcostitem == "Marine Cover")
      ) {
        setamountinUSD(parseFloat(costpermt) * parseFloat(wheatcost));
        // setexchangerate(140);
        setexchangerate(initialexch);
        setamountinKSH(
          parseFloat(costpermt) * parseFloat(wheatcost) * initialexch
        );
        setamountinclvat(
          parseFloat(costpermt) * parseFloat(wheatcost) * initialexch
        );
      } else {
        setamountinUSD(parseFloat(costpermt) * parseFloat(quantityinmt));
        // setexchangerate(140);
        setexchangerate(initialexch);
        setamountinKSH(
          parseFloat(costpermt) * parseFloat(quantityinmt) * initialexch
        );
        setamountinclvat(
          parseFloat(costpermt) * parseFloat(quantityinmt) * initialexch
        );
      }
    }
  };

  const handlevat = (value) => {
    if (value === 1) {
      const amount = amountinKSH * 0.16;
      setamountinclvat(Number(amountinKSH) + Number(amount));
    } else {
      setamountinclvat(amountinKSH);
    }
  };

  // Adding into Tables *********************************************************************************
  const AddCostItem = () => {
    if (!selItemCode || !selitemname) {
      alert("Please select Item first");
    } else if (!selcostitem) {
      alert("Please add the cost item");
    } else if (!quantityinmt) {
      alert("Please enter a Quanity value.");
    } else if (!supplierName) {
      alert("Please Choose a Supplier Name.");
    } else if (!vesselName) {
      alert("Please enter a Vessel Name.");
    } else {
      // let qty = 0;
      // if (
      //   wheatcost &&
      //   (selcostitem == "Import Duty" || selcostitem == "Marine Cover")
      // ) {
      //   qty = wheatcost;
      // } else {
      //   qty = quantityinmt;
      // }
      if (flag === 1) {
        let updatedvalue = [...tabledata];
        updatedvalue[index] = {
          description: selcostitem,
          quanity: quantityinmt,
          cost: costpermt,
          amountUSD: amountinUSD,
          exch: exchangerate,
          amountExclVAT: amountinKSH,
          amountInclVAT: amountinclvat,
          narration: narration,
        };
        setTabledata(updatedvalue);
        setShowTable(true);
        clearallinputs();
        setflag(0);
        setIndex(null);
      } else {
        const newValue = {
          description: selcostitem,
          quanity: quantityinmt,
          cost: costpermt,
          amountUSD: amountinUSD,
          exch: exchangerate,
          amountExclVAT: amountinKSH,
          amountInclVAT: amountinclvat,
          narration: narration,
        };

        const isDuplicateRow = tabledata.some((charge) => {
          return charge.description === newValue.description;
        });

        if (isDuplicateRow) {
          alert("This Cost Item has been added previusly.");
        } else {
          setTabledata([...tabledata, newValue]);
          let inctotal = 0;
          let excvat = 0;
          for (var i = 0; i < tabledata.length; i++) {
            inctotal = inctotal + tabledata[i].amountInclVAT;
            excvat = excvat + tabledata[i].amountExclVAT;
          }
          inctotal = inctotal + newValue.amountInclVAT;
          excvat = excvat + newValue.amountExclVAT;
          handleExclInclVAT(excvat, inctotal);
        }
        setShowTable(true);
        clearallinputs();
      }
    }
  };

  // Add New Transport
  const addnewCostItem = () => {
    if (!selItemCode || !selitemname) {
      alert("Please select Item first");
      setShowTable(false);
    } else if (!selcostitem) {
      alert("Please add the cost item");
      setShowTable(false);
    } else if (!quantityinmt) {
      alert("Please enter a Quanity value.");
      setShowTable(false);
    } else if (!supplierName) {
      alert("Please Choose a Supplier Name.");
      setShowTable(false);
    } else if (!vesselName) {
      alert("Please enter a Vessel Name.");
      setShowTable(false);
    } else {
      // let qty = 0;
      // if (
      //   wheatcost &&
      //   (selcostitem == "Import Duty" || selcostitem == "Marine Cover")
      // ) {
      //   qty = wheatcost;
      // } else {
      //   qty = quantityinmt;
      // }
      const newValue = {
        OT_COSTING_SHEET_CHARGE_CODE: selcostitem,
        OT_COSTING_SHEET_QTY: quantityinmt,
        OT_COSTING_SHEET_VAT_COST_PER_UNIT: costpermt,
        OT_COSTING_SHEET_CHARGE_RATE_USD: amountinUSD,
        OT_COSTING_SHEET_CHARGE_EXCH_RATE: exchangerate,
        OT_COSTING_SHEET_COST_EXC_VAT_KSH: amountinKSH,
        OT_COSTING_SHEET_CHARGE_COST_TOTAL: amountinclvat,
        OT_COSTING_SHEET_DESCRIPTION: narration,
      };

      const isDuplicateRow = historicdata.some((charge) => {
        return (
          charge.OT_COSTING_SHEET_CHARGE_CODE ===
          newValue.OT_COSTING_SHEET_CHARGE_CODE
        );
      });

      const isDuplicateRow1 = updateddata.some((charge) => {
        return (
          charge.OT_COSTING_SHEET_CHARGE_CODE ===
          newValue.OT_COSTING_SHEET_CHARGE_CODE
        );
      });

      if (isDuplicateRow || isDuplicateRow1) {
        alert("This Cost Item has been added previusly.");
      } else {
        let inctotal = 0;
        let excvat = 0;
        if (updateddata.length > 0) {
          for (var i = 0; i < updateddata.length; i++) {
            inctotal =
              inctotal + updateddata[i].OT_COSTING_SHEET_CHARGE_COST_TOTAL;
            excvat = excvat + updateddata[i].OT_COSTING_SHEET_COST_EXC_VAT_KSH;
          }
        } else {
          for (var i = 0; i < historicdata.length; i++) {
            inctotal =
              inctotal + historicdata[i].OT_COSTING_SHEET_CHARGE_COST_TOTAL;
            excvat = excvat + historicdata[i].OT_COSTING_SHEET_COST_EXC_VAT_KSH;
          }
        }
        inctotal = inctotal + newValue.OT_COSTING_SHEET_CHARGE_COST_TOTAL;
        excvat = excvat + newValue.OT_COSTING_SHEET_COST_EXC_VAT_KSH;
        handleExclInclVAT(excvat, inctotal);

        handleOtherChargesModified(finalothercharges1, excvat, inctotal);
        if (updateddata.length > 0) {
          setUpdateddata([...updateddata, newValue]);
        } else if (historicdata.length > 0) {
          setUpdateddata([...historicdata, newValue]);
        } else {
          setUpdateddata([...updateddata, newValue]);
        }
      }
    }
    setShowTable(true);
    clearallinputs();
  };

  // Modifying Transport Details
  const handleModify = () => {
    if (!selItemCode || !selitemname) {
      alert("Please select Item first");
      setShowTable(false);
    } else if (!selcostitem) {
      alert("Please add the cost item");
      setShowTable(false);
    } else if (!quantityinmt) {
      alert("Please enter a Quanity value.");
      setShowTable(false);
    } else if (!supplierName) {
      alert("Please Choose a Supplier Name.");
      setShowTable(false);
    } else if (!vesselName) {
      alert("Please enter a Vessel Name.");
      setShowTable(false);
    } else {
      // let qty = 0;
      // if (
      //   wheatcost &&
      //   (selcostitem == "Import Duty" || selcostitem == "Marine Cover")
      // ) {
      //   qty = wheatcost;
      // } else {
      //   qty = quantityinmt;
      // }
      let updatedValues = [];
      if (updateddata.length > 0) {
        updatedValues = [...updateddata];
      } else {
        updatedValues = [...historicdata];
      }
      updatedValues[index1] = {
        OT_COSTING_SHEET_CHARGE_CODE: selcostitem,
        OT_COSTING_SHEET_QTY: quantityinmt,
        OT_COSTING_SHEET_VAT_COST_PER_UNIT: costpermt,
        OT_COSTING_SHEET_CHARGE_RATE_USD: amountinUSD,
        OT_COSTING_SHEET_CHARGE_EXCH_RATE: exchangerate,
        OT_COSTING_SHEET_COST_EXC_VAT_KSH: amountinKSH,
        OT_COSTING_SHEET_CHARGE_COST_TOTAL: amountinclvat,
        OT_COSTING_SHEET_DESCRIPTION: narration,
      };

      let inctotal = 0;
      let excvat = 0;
      for (var i = 0; i < updatedValues.length; i++) {
        inctotal =
          inctotal + updatedValues[i].OT_COSTING_SHEET_CHARGE_COST_TOTAL;
        excvat = excvat + updatedValues[i].OT_COSTING_SHEET_COST_EXC_VAT_KSH;
      }
      handleExclInclVAT(excvat, inctotal);
      setUpdateddata(updatedValues);
      setIndex1(null);
      setflag1(0);
      setShowTable(true);
      if (updateddata1.length > 0 || historicdata1.length > 0) {
        handleOtherChargesModified(finalothercharges1, excvat, inctotal);
      }
      clearallinputs();
    }
  };

  const clearallinputs = () => {
    setselcostitem("");
    setcostpermt("");
    setamountinKSH("");
    setamountinUSD("");
    setexchangerate(null);
    setNarration("");
  };

  const RemoveRawMaterials = () => {
    if (tabledata1.length > 0) {
      alert("You can not remove a raw material when there is Other charges");
    } else {
      const updatedValues = [...tabledata];
      const totalexcl = tabledata[tabledata.length - 1].amountExclVAT;
      const totalincl = tabledata[tabledata.length - 1].amountInclVAT;
      const excl = totalcostexclvat - Number(totalexcl);
      const finalexcl = Number(excl) / Number(quantityinmt);
      const incl = totalcostinclvat - Number(totalincl);
      const finalincl = Number(incl) / Number(quantityinmt);
      totalcostexclvat = excl;
      totalcostinclvat = incl;
      setcostpermtexclvat(Number(finalexcl));
      setcostperkiloexclvat(Number(finalexcl) / 1000);
      setcostper90kgsexclvat((Number(finalexcl) / 1000) * 90);
      setcostpermtinclvat(Number(finalincl));
      setcostperkiloinclvat(Number(finalincl) / 1000);
      setcostper90kgsinclvat((Number(finalincl) / 1000) * 90);

      if (index !== null && index >= 0 && index < updatedValues.length) {
        updatedValues.splice(index, 1);
        setIndex(null);
      } else {
        updatedValues.pop();
      }

      setTabledata(updatedValues);

      if (updatedValues.length === 0) {
        totalcostexclvat = 0;
        totalcostinclvat = 0;
        setcostpermtexclvat(0);
        setcostperkiloexclvat(0);
        setcostper90kgsexclvat(0);
        setcostpermtinclvat(0);
        setcostperkiloinclvat(0);
        setcostper90kgsinclvat(0);
        setShowTable(false);
      }
      if (ShowTable == false) {
        alert("Kindly add Cost Item to remove");
      }
    }
    clearallinputs();
  };

  const RemoveRawMaterialsprod = () => {
    if (window.confirm("Are you sure you want to remove Raw Material")) {
      setflag1(0);
      let updatedValues = [];
      if (updateddata.length > 0) {
        updatedValues = [...updateddata];
      } else {
        updatedValues = [...historicdata];
      }

      const totalexcl =
        updatedValues[index1 != null ? index1 : updatedValues.length - 1]
          .OT_COSTING_SHEET_COST_EXC_VAT_KSH;
      const totalincl =
        updatedValues[index1 != null ? index1 : updatedValues.length - 1]
          .OT_COSTING_SHEET_CHARGE_COST_TOTAL;
      const excl = totalcostexclvat - Number(totalexcl);
      const finalexcl = Number(excl) / Number(quantityinmt);
      const incl = totalcostinclvat - Number(totalincl);
      const finalincl = Number(incl) / Number(quantityinmt);
      totalcostexclvat = excl;
      totalcostinclvat = incl;
      setcostpermtexclvat(Number(finalexcl));
      setcostperkiloexclvat(Number(finalexcl) / 1000);
      setcostper90kgsexclvat((Number(finalexcl) / 1000) * 90);
      setcostpermtinclvat(Number(finalincl));
      setcostperkiloinclvat(Number(finalincl) / 1000);
      setcostper90kgsinclvat((Number(finalincl) / 1000) * 90);

      if (index1 !== null && index1 >= 0 && index1 < updatedValues.length) {
        const isExisting = historicdata.some((charge) => {
          return (
            charge.OT_COSTING_SHEET_CHARGE_CODE ===
            updatedValues[index1].OT_COSTING_SHEET_CHARGE_CODE
          );
        });

        if (isExisting) {
          DeleteCostItem(updatedValues[index1].OT_COSTING_SHEET_CHARGE_CODE);
          updatedValues.splice(index1, 1);
          setIndex1(null);
        } else {
          updatedValues.splice(index1, 1);
          setIndex1(null);
        }
      } else {
        const isExisting = historicdata.some((charge) => {
          return (
            charge.OT_COSTING_SHEET_CHARGE_CODE ===
            updatedValues[updatedValues.length - 1].OT_COSTING_SHEET_CHARGE_CODE
          );
        });
        if (isExisting) {
          DeleteCostItem(
            updatedValues[updatedValues.length - 1].OT_COSTING_SHEET_CHARGE_CODE
          );
          updatedValues.pop();
        } else {
          updatedValues.pop();
        }
      }
      setHistoricData(updatedValues);
      setUpdateddata(updatedValues);

      if (updatedValues.length === 0) {
        totalcostexclvat = 0;
        totalcostinclvat = 0;
        setcostpermtexclvat(0);
        setcostperkiloexclvat(0);
        setcostper90kgsexclvat(0);
        setcostpermtinclvat(0);
        setcostperkiloinclvat(0);
        setcostper90kgsinclvat(0);
        settotalcostexclvatother(0);
        setcostpermtexclvatother(0);
        setcostperkiloexclvatother(0);
        setcostper90kgsexclvatother(0);
        settotalcostinclvatother(0);
        setcostpermtinclvatother(0);
        setcostperkiloinclvatother(0);
        setcostper90kgsinclvatother(0);
        setShowTable(false);
      }
      if (ShowTable === false) {
        alert("Please add Raw Material First");
      }
    }
    clearallinputs();
  };

  //   Other Charges

  const AddOtherCharges = () => {
    if (tabledata.length <= 0) {
      alert("Please add the cost items first");
    } else if (!selothercharges) {
      alert("Please Select OtherCharges first");
    } else {
      if (flag3 === 1) {
        let updatedvalue = [...tabledata1];
        updatedvalue[index3] = {
          description: selothercharges,
          cost: otherchargescost,
        };
        setTabledata1(updatedvalue);
        let final = 0;
        for (var i = 0; i < updatedvalue.length; i++) {
          final = final + updatedvalue[i].cost;
        }
        handleOtherCharges(final);
        setShowTable1(true);
        clearOtherCharges();
        setflag3(0);
      } else {
        const newValue = {
          description: selothercharges,
          cost: otherchargescost,
        };

        const isDuplicateRow = tabledata1.some((charge) => {
          return charge.description === newValue.description;
        });

        if (isDuplicateRow) {
          alert("This Other Charge has been added previusly.");
        } else {
          setTabledata1([...tabledata1, newValue]);
          let final = 0;
          for (var i = 0; i < tabledata1.length; i++) {
            final = final + tabledata1[i].cost;
          }
          final = final + newValue.cost;
          handleOtherCharges(final);
        }

        setShowTable1(true);
        clearOtherCharges();
      }
    }
  };

  // Add New Transport
  const addnewOtherCharges = () => {
    const newValue = {
      OTHERCHARGE: selothercharges,
      OTHERCHARGEAMOUNT: otherchargescost,
    };

    let isDuplicateRow = false;
    if (updateddata1.length > 0) {
      isDuplicateRow = updateddata1.some((charge) => {
        return charge.OTHERCHARGE === newValue.OTHERCHARGE;
      });
    } else {
      isDuplicateRow = historicdata1.some((charge) => {
        return charge.OTHERCHARGE === newValue.OTHERCHARGE;
      });
    }

    if (isDuplicateRow) {
      alert("This Other Charge has been added previusly.");
    } else {
      let final = 0;
      if (updateddata1.length > 0) {
        for (var i = 0; i < updateddata1.length; i++) {
          final = final + updateddata1[i].OTHERCHARGEAMOUNT;
        }
      } else {
        for (var i = 0; i < historicdata1.length; i++) {
          final = final + historicdata1[i].OTHERCHARGEAMOUNT;
        }
      }
      final = final + Number(newValue.OTHERCHARGEAMOUNT);
      handleOtherCharges(final);

      if (updateddata1.length > 0) {
        setupdateddata1([...updateddata1, newValue]);
      } else if (historicdata1.length > 0) {
        setupdateddata1([...historicdata1, newValue]);
      } else {
        setupdateddata1([...updateddata1, newValue]);
      }

      setShowTable1(true);
      clearOtherCharges();
    }
  };

  // Modifying Transport Details
  const handleModify1 = () => {
    let updatedValues = [];
    if (updateddata1.length > 0) {
      updatedValues = [...updateddata1];
    } else {
      updatedValues = [...historicdata1];
    }

    updatedValues[index2] = {
      OTHERCHARGE: selothercharges,
      OTHERCHARGEAMOUNT: otherchargescost,
    };

    let final = 0;
    for (var i = 0; i < updatedValues.length; i++) {
      final = final + updatedValues[i].OTHERCHARGEAMOUNT;
    }
    handleOtherCharges(final);
    setupdateddata1(updatedValues);
    setIndex2(null);
    setShowTable1(true);
    clearOtherCharges();
  };

  const clearOtherCharges = () => {
    setselothercharges("");
    setOtherChargesCost("");
  };

  const RemoveOtherChargesProd = () => {
    if (window.confirm("Are you sure you want to remove Other Charges")) {
      setflag2(0);
      let updatedValues = [];
      if (updateddata1.length > 0) {
        updatedValues = [...updateddata1];
      } else {
        updatedValues = [...historicdata1];
      }

      const totalcharge =
        updatedValues[index2 != null ? index2 : updatedValues.length - 1]
          .OTHERCHARGEAMOUNT;
      const excl =
        updatedexcl > 0 ? updatedexcl : totalcostexclvat1 - Number(totalcharge);
      const finalexcl = Number(excl) / Number(quantityinmt);
      const incl =
        updatedincl > 0 ? updatedincl : totalcostinclvat1 - Number(totalcharge);
      const finalincl = Number(incl) / Number(quantityinmt);

      settotalcostexclvatother(Number(excl));
      setcostpermtexclvatother(Number(finalexcl));
      setcostperkiloexclvatother(Number(finalexcl) / 1000);
      setcostper90kgsexclvatother((Number(finalexcl) / 1000) * 90);
      settotalcostinclvatother(Number(incl));
      setcostpermtinclvatother(Number(finalincl));
      setcostperkiloinclvatother(Number(finalincl) / 1000);
      setcostper90kgsinclvatother((Number(finalincl) / 1000) * 90);

      if (index2 !== null && index2 >= 0 && index2 < updatedValues.length) {
        const isExisting = historicdata1.some((charge) => {
          return charge.OTHERCHARGE === updatedValues[index2].OTHERCHARGE;
        });

        if (isExisting) {
          DeleteOtherCharges(updatedValues[index2].OTHERCHARGE);
          updatedValues.splice(index2, 1);
          setIndex2(null);
        } else {
          updatedValues.splice(index2, 1);
          setIndex2(null);
        }
      } else {
        const isExisting = historicdata.some((charge) => {
          return (
            charge.OTHERCHARGE ===
            updatedValues[updatedValues.length - 1].OTHERCHARGE
          );
        });
        if (isExisting) {
          DeleteOtherCharges(
            updatedValues[updatedValues.length - 1].OTHERCHARGE
          );
          updatedValues.pop();
        } else {
          updatedValues.pop();
        }
      }
      setHistoricData1(updatedValues);
      setupdateddata1(updatedValues);

      if (updatedValues.length === 0) {
        settotalcostexclvatother(0);
        setcostpermtexclvatother(0);
        setcostperkiloexclvatother(0);
        setcostper90kgsexclvatother(0);
        settotalcostinclvatother(0);
        setcostpermtinclvatother(0);
        setcostperkiloinclvatother(0);
        setcostper90kgsinclvatother(0);
        setShowTable1(false);
      }
      if (ShowTable1 == false) {
        alert("Kindly add Other Charges to remove");
      }
    }
    clearOtherCharges();
  };

  const RemoveOtherCharges = () => {
    const updatedValues = [...tabledata1];
    const totalcharge = tabledata1[tabledata1.length - 1].cost;
    const excl = totalcostexclvatother - Number(totalcharge);
    const finalexcl = Number(excl) / Number(quantityinmt);
    const incl = totalcostinclvatother - Number(totalcharge);
    const finalincl = Number(incl) / Number(quantityinmt);
    settotalcostexclvatother(Number(excl));
    setcostpermtexclvatother(Number(finalexcl));
    setcostperkiloexclvatother(Number(finalexcl) / 1000);
    setcostper90kgsexclvatother((Number(finalexcl) / 1000) * 90);
    settotalcostinclvatother(Number(incl));
    setcostpermtinclvatother(Number(finalincl));
    setcostperkiloinclvatother(Number(finalincl) / 1000);
    setcostper90kgsinclvatother((Number(finalincl) / 1000) * 90);

    if (index3 !== null && index3 >= 0 && index3 < updatedValues.length) {
      updatedValues.splice(index3, 1);
      setIndex3(null);
    } else {
      updatedValues.pop();
    }

    setTabledata1(updatedValues);
    if (updatedValues.length === 0) {
      settotalcostexclvatother(0);
      setcostpermtexclvatother(0);
      setcostperkiloexclvatother(0);
      setcostper90kgsexclvatother(0);
      settotalcostinclvatother(0);
      setcostpermtinclvatother(0);
      setcostperkiloinclvatother(0);
      setcostper90kgsinclvatother(0);
      setShowTable1(false);
    }
    if (ShowTable1 == false) {
      alert("Kindly add Other Charges to remove");
    }
    clearOtherCharges();
  };

  //   Save Processs starts here ****************************************************************

  async function SaveHeaders(importcostingno) {
    try {
      const response = await axios.post(
        `${BASEURL}/InsertImportCostingHeader`,
        {
          costingno: importcostingno,
          itemcode: selItemCode,
          date: date,
          costpermtexcl: costpermtexclvat,
          costperkiloexcl: costperkiloexclvat,
          costper90kgexcl: costper90kgsexclvat,
          costpermtincl: costpermtinclvat,
          costperkiloincl: costperkiloinclvat,
          costper90kgincl: costper90kgsinclvat,
          supplierCode: supplierCode,
          vesselName: vesselName,
          wheatcostperMT: Number(wheatcost),
          user: username.split("@")[0].toUpperCase(),
          costpermtexclother: costpermtexclvatother,
          costperkiloexclother: costperkiloexclvatother,
          costper90kgexclother: costper90kgsexclvatother,
          costpermtinclother: costpermtinclvatother,
          costperkiloinclother: costperkiloinclvatother,
          costper90kginclother: costper90kgsinclvatother,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );

      if (response.status === 200) {
        alert(response.data.message);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error(`Error while saving row headers`, error);
      alert(error.response.data.message);
    }
  }

  async function SaveCostItem() {
    setisloading(true);
    let successCount = 0;
    let successMessage = null;
    for (let indexx = 0; indexx < tabledata.length; indexx++) {
      const value = tabledata[indexx];
      try {
        const response = await axios.post(
          `${BASEURL}/InsertCostItemDetails`,
          {
            costingno: importcostingno,
            itemcode: value.description,
            amountinUSD: value.amountUSD,
            exchrate: value.exch,
            amountinKSH: value.amountExclVAT,
            costpermt: value.cost,
            amountinclvat: value.amountInclVAT,
            quanity: value.quanity,
            description: value.narration,
            index: indexx + 1,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        );

        if (response.status === 200) {
          successCount++;
          successMessage = response.data.message;
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        setisloading(false);
        console.error(`Error while saving row `, error);
        alert(error.response.data.message);
      }
    }
    setisloading(false);
    if (successCount == tabledata.length && successMessage) {
      alert(successMessage);
    }
  }

  async function DeleteCostItem(value) {
    try {
      const response = await axios.post(
        `${BASEURL}/DeleteCostItemDetails`,
        {
          costingno: Number(selcostingno),
          itemcode: value,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );

      if (response.status === 200) {
        alert(response.data.message);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error(`Error while saving row headers`, error);
      alert(error.response.data.message);
    }
  }

  async function SaveOtherCharges() {
    setisloading(true);
    let successCount = 0;
    let successMessage = null;
    for (let index = 0; index < tabledata1.length; index++) {
      const value = tabledata1[index];
      try {
        const response = await axios.post(
          `${BASEURL}/InsertImportOtherCharges`,
          {
            costingno: importcostingno,
            otherchargename: value.description,
            othercharge: value.cost,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        );

        if (response.status === 200) {
          successCount++;
          successMessage = response.data.message;
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        setisloading(false);
        console.error(`Error while saving row `, error);
        alert(error.response.data.message);
      }
    }
    setisloading(false);
    if (successCount == tabledata1.length && successMessage) {
      alert(successMessage);
    }
  }

  async function DeleteOtherCharges(value) {
    try {
      const response = await axios.post(
        `${BASEURL}/DeleteImportOtherCharges`,
        {
          costingno: Number(selcostingno),
          otherchargename: value,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );

      if (response.status === 200) {
        alert(response.data.message);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error(`Error while saving row headers`, error);
      alert(error.response.data.message);
    }
  }

  async function UpdateItemPrice() {
    try {
      const response = await axios.post(
        `${BASEURL}/UpdateItemPrice`,
        {
          itemcode: selItemCode,
          cost:
            Number(costper90kgsinclvatother) > 0
              ? Number(costper90kgsinclvatother)
              : Number(costper90kgsinclvat),
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );

      if (response.status === 200) {
        alert(response.data.message);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error(`Error while saving row headers`, error);
      alert(error.response.data.message);
    }
  }
  async function UpdateCostItem(importcostingno) {
    setisloading(true);
    let successCount = 0;
    let successMessage = null;
    for (let indexx = 0; indexx < updateddata.length; indexx++) {
      const value = updateddata[indexx];
      try {
        const response = await axios.post(
          `${BASEURL}/InsertCostItemDetails`,
          {
            costingno: importcostingno,
            itemcode: value.OT_COSTING_SHEET_CHARGE_CODE,
            amountinUSD: value.OT_COSTING_SHEET_CHARGE_RATE_USD,
            exchrate: value.OT_COSTING_SHEET_CHARGE_EXCH_RATE,
            amountinKSH: value.OT_COSTING_SHEET_COST_EXC_VAT_KSH,
            costpermt: value.OT_COSTING_SHEET_VAT_COST_PER_UNIT,
            amountinclvat: value.OT_COSTING_SHEET_CHARGE_COST_TOTAL,
            quanity: value.OT_COSTING_SHEET_QTY,
            description: value.OT_COSTING_SHEET_DESCRIPTION,
            index: indexx + 1,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        );

        if (response.status === 200) {
          successCount++;
          successMessage = response.data.message;
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        setisloading(false);
        console.error(`Error while saving row `, error);
        alert(error.response.data.message);
      }
    }
    setisloading(false);
    if (successCount == updateddata.length && successMessage) {
      alert(successMessage);
    }
  }

  async function UpdateOtherCharges(importcostingno) {
    setisloading(true);
    let successCount = 0;
    let successMessage = null;
    for (let index = 0; index < updateddata1.length; index++) {
      const value = updateddata1[index];
      try {
        const response = await axios.post(
          `${BASEURL}/InsertImportOtherCharges`,
          {
            costingno: importcostingno,
            otherchargename: value.OTHERCHARGE,
            othercharge: value.OTHERCHARGEAMOUNT,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        );

        if (response.status === 200) {
          successCount++;
          successMessage = response.data.message;
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        setisloading(false);
        console.error(`Error while saving row `, error);
        alert(error.response.data.message);
      }
    }
    setisloading(false);
    if (successCount == updateddata1.length && successMessage) {
      alert(successMessage);
    }
  }

  //   fetching historic details *********************************************************************
  const getHistoricCosting = (costing) => {
    setHist(true);
    setselcostingno(costing);
    setisloading(true);
    axios
      .post(
        `${BASEURL}/FetchImportCosting`,
        {
          costingno: costing,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setselItemCode(response.data[0].ITEMCODE);
        setDate(response?.data[0]?.OT_COSTING_SHEET_DATE);
        setselItemName(response.data[0].ITEMDESCRIPTION);
        setselhistoricraw(response.data[0].ITEMDESCRIPTION);
        setVesselName(response.data[0].VESSELNAME);
        setquantityinmt(response.data[0].OT_COSTING_SHEET_QTY);
        setWheatCost(response.data[0].WHEATCOST);
        setHistoricData(response.data);
        setcostpermtexclvat(
          response.data[0].OT_COSTING_SHEET_CHARGE_PER_MT_EXCLUSIVE
        );
        setcostperkiloexclvat(
          response.data[0].OT_COSTING_SHEET_CHARGE_PER_KILO_EXCLUSIVE
        );
        setcostper90kgsexclvat(
          response.data[0].OT_COSTING_SHEET_CHARGE_PER_90KG_EXCLUSIVE
        );
        setcostpermtinclvat(
          response.data[0].OT_COSTING_SHEET_CHARGE_PER_MT_INCLUSIVE
        );
        setcostperkiloinclvat(
          response.data[0].OT_COSTING_SHEET_CHARGE_PER_KILO_INCLUSIVE
        );
        setcostper90kgsinclvat(
          response.data[0].OT_COSTING_SHEET_CHARGE_PER_90KG_INCLUSIVE
        );
        setcostpermtexclvatother(
          response.data[0]
            .OT_COSTING_SHEET_CHARGE_PER_MT_WITH_OTHER_CHARGES_EXCLUSIVE
        );
        setcostperkiloexclvatother(
          response.data[0]
            .OT_COSTING_SHEET_CHARGE_PER_KG_WITH_OTHER_CHARGES_EXCLUSIVE
        );
        setcostper90kgsexclvatother(
          response.data[0]
            .OT_COSTING_SHEET_CHARGE_PER_90KG_WITH_OTHER_CHARGES_EXCLUSIVE
        );
        setcostpermtinclvatother(
          response.data[0]
            .OT_COSTING_SHEET_CHARGE_PER_MT_WITH_OTHER_CHARGES_INCLUSIVE
        );
        setcostperkiloinclvatother(
          response.data[0]
            .OT_COSTING_SHEET_CHARGE_PER_KG_WITH_OTHER_CHARGES_INCLUSIVE
        );
        setcostper90kgsinclvatother(
          response.data[0]
            .OT_COSTING_SHEET_CHARGE_PER_90KG_WITH_OTHER_CHARGES_INCLUSIVE
        );
        handleSupplierCode(response.data[0]?.SUPPLIERCODE);
        setisloading(false);
        setShowTable(true);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching costingno:", error);
      });
  };

  const getHistoricCosting1 = (costing) => {
    setisloading(true);
    axios
      .post(
        `${BASEURL}/FetchImportCosting1`,
        {
          costingno: costing,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setHistoricData1(response.data);
        setisloading(false);
        setShowTable1(true);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching costingno:", error);
      });
  };

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <div className="toHIde__when__Print">
        <div>
          <AllNav />

          <h1 style={{ textAlign: "center" }}>Import Costing</h1>
          <div className="header_import_import">
            <div className="import">
              <p>IMPORT COSTING</p>
            </div>
            <div>
              <button
                className="Save_import"
                onClick={() => {
                  if (tabledata.length > 0) {
                    if (window.confirm("Are you sure you don't want to Save")) {
                      dispatch(costingno(""));
                      window.location.reload();
                    }
                  } else {
                    dispatch(costingno(""));
                    window.location.reload();
                  }
                }}
              >
                New
              </button>
              <button
                className="Save_import"
                onClick={async () => {
                  if (selcostingno) {
                    if (updateddata.length > 0 || updateddata1.length > 0) {
                      setisloading(true);
                      await SaveHeaders(importcostingno);
                      await UpdateCostItem(importcostingno);
                      await UpdateOtherCharges(importcostingno);
                      await UpdateItemPrice();
                      setisloading(false);
                      // alert("Saved as new Costing Succesfully");
                      window.location.reload();
                    } else {
                      alert("Kindly alter the data to Update");
                    }
                  } else {
                    if (tabledata.length > 0) {
                      setisloading(true);
                      await SaveHeaders(importcostingno);
                      await SaveCostItem();
                      await SaveOtherCharges();
                      await UpdateItemPrice();
                      setisloading(false);
                      // alert("Saved Succesfully");
                      window.location.reload();
                    } else {
                      alert("Kindly Add all the fields to save the data");
                    }
                  }
                }}
              >
                Save
              </button>
              <button
                className="Save_import"
                onClick={async () => {
                  if (selcostingno) {
                    if (updateddata.length > 0 || updateddata1.length > 0) {
                      setisloading(true);
                      await SaveHeaders(selcostingno);
                      await UpdateCostItem(selcostingno);
                      await UpdateOtherCharges(selcostingno);
                      await UpdateItemPrice();
                      setisloading(false);
                      // alert("Updated Succesfully");
                      window.location.reload();
                    } else {
                      alert("Kindly alter the data to Update");
                    }
                  } else {
                    alert("You can not Update this, click on Save");
                  }
                }}
              >
                Update
              </button>

              <button
                className="Save_import"
                style={{ width: "150px" }}
                onClick={async () => {
                  if (selcostingno) {
                    console.log(importcostingno);
                    setHist(false);
                    setimportcostingno(importcostingno);
                    setselhistoricraw("");
                    setselcostingno("");
                    // alert("Updated Succesfully");
                  } else {
                    alert("You can not Duplicate this, click on Save");
                  }
                }}
              >
                Duplicate And New
              </button>

              <button
                className="Save_import"
                onClick={() => {
                  if (
                    updateddata.length > 0 ||
                    historicdata.length > 0 ||
                    tabledata.length > 0
                  ) {
                    window.print();
                  } else {
                    alert("Kindly add the data to Print");
                  }
                }}
              >
                Print
              </button>
            </div>
          </div>
        </div>
        <div className="Import_first_line">
          <div className="historical_prod_no">
            <div className="hist_prod">
              <p>Historical Raw Materials</p>
              <Select
                className="select_name"
                onChange={(selectedOption) => {
                  if (selectedOption.value === "") {
                    alert("Kindly Supply Proper Raw Material");
                  } else {
                    if (selcostingno) {
                      setHistoricData([]);
                      setHistoricData1([]);
                      setUpdateddata([]);
                      setupdateddata1([]);
                      totalcostexclvat = 0;
                      totalcostinclvat = 0;
                      setcostpermtexclvat(0);
                      setcostperkiloexclvat(0);
                      setcostper90kgsexclvat(0);
                      setcostpermtinclvat(0);
                      setcostperkiloinclvat(0);
                      setcostper90kgsinclvat(0);
                      settotalcostexclvatother(0);
                      setcostpermtexclvatother(0);
                      setcostperkiloexclvatother(0);
                      setcostper90kgsexclvatother(0);
                      settotalcostinclvatother(0);
                      setcostpermtinclvatother(0);
                      setcostperkiloinclvatother(0);
                      setcostper90kgsinclvatother(0);
                      setShowTable(false);
                      setShowTable1(false);
                      setselItemCode("");
                      setselItemName("");
                      setquantityinmt("");
                      setWheatCost("");
                      setselcostingno("");
                      setselhistoricraw(selectedOption.value);
                      handleItemNamehistoric(selectedOption.value);
                    } else {
                      setselhistoricraw(selectedOption.value);
                      handleItemNamehistoric(selectedOption.value);
                    }
                  }
                }}
                value={
                  selhistoricraw
                    ? { label: selhistoricraw, value: selhistoricraw }
                    : { label: "", value: "" }
                }
                options={[
                  { value: "", label: "" },
                  ...rawmaterial.map((item) => ({
                    value: item.ITEMDESCRIPTION,
                    label: item.ITEMDESCRIPTION,
                  })),
                ]}
              />
            </div>
            <div className="hist_prod1">
              <p style={{ marginLeft: "5px" }}>Historical Cost Sheet No</p>
              <Select
                className="pro_select"
                onChange={(selectedOption) => {
                  if (selectedOption.value === "") {
                    alert("Kindly Supply Proper Cost Sheet No");
                  } else {
                    setHist(true);
                    setselcostingno(selectedOption.value);
                    getHistoricCosting(selectedOption.value);
                    getHistoricCosting1(selectedOption.value);
                  }
                }}
                value={
                  selcostingno
                    ? { label: selcostingno, value: selcostingno }
                    : { label: "", value: "" }
                }
                options={[
                  { value: "", label: "" },
                  ...listofcostingno.map((item) => ({
                    value: item.OT_COSTING_SHEET_NO,
                    label: item.OT_COSTING_SHEET_NO,
                  })),
                ]}
              />
              {/* )} */}
            </div>

            <div className="raw_date">
              <p>Date</p>
              <input
                className="input_date_import"
                type="Date"
                value={date.split("T")[0]}
                onChange={(e) => setDate(e.target.value)}
                disabled={hist == true}
              ></input>
            </div>
          </div>
          {/* 2nd line */}
          <div className="import_second_line">
            <div className="cost_sheet_no_1">
              <p>Cost Sheet No </p>
              <input
                className="input_size_bag5"
                value={hist ? "" : importcostingno}
                readOnly
              ></input>
            </div>
            <div className="item_1">
              <p>Item Name </p>
              <div style={{ display: "flex", gap: "10px" }}>
                <Select
                  className="select_name"
                  onChange={(selectedOption) => {
                    if (selectedOption.value == "") {
                      alert("Kindly Provide Proper Item Name ");
                    } else {
                      handleItemName(selectedOption.value);
                    }
                  }}
                  value={
                    selitemname
                      ? { label: selitemname, value: selitemname }
                      : { label: "", value: "" }
                  }
                  options={[
                    { value: "", label: "" },
                    ...rawmaterial.map((item) => ({
                      value: item.ITEMDESCRIPTION,
                      label: item.ITEMDESCRIPTION,
                    })),
                  ]}
                  isDisabled={hist == true}
                />
                <Select
                  className="select_code"
                  onChange={(e) => {
                    if (e.value == "") {
                      alert("Kindly Provide Proper Item Code");
                    } else {
                      handleItemCode(e.value);
                    }
                  }}
                  value={
                    selItemCode
                      ? { label: selItemCode, value: selItemCode }
                      : { label: "", value: "" }
                  }
                  options={[
                    { value: "", label: "" },
                    ...rawmaterial.map((item) => ({
                      value: item.ITEMCODE,
                      label: item.ITEMCODE,
                    })),
                  ]}
                  isDisabled={hist == true}
                />
              </div>
            </div>
            <div className="qty_import">
              <p>Quantity in MT</p>
              <input
                type="text"
                className="input_size_bag6"
                onChange={(e) => {
                  if (selItemCode) {
                    setquantityinmt(e.target.value);
                  } else {
                    alert("Kindly choose the item first");
                  }
                }}
                value={
                  quantityinmt
                    ? quantityinmt.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })
                    : ""
                }
                disabled={hist == true}
              ></input>
            </div>
            <div className="qty_import">
              <p>Wheat Cost in MT</p>
              <input
                type="text"
                className="input_size_bag6"
                onChange={(e) => {
                  if (selItemCode) {
                    setWheatCost(e.target.value);
                  } else {
                    alert("Kindly choose the item first");
                  }
                }}
                value={
                  wheatcost
                    ? wheatcost.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })
                    : ""
                }
                disabled={hist == true}
              ></input>
            </div>
          </div>
          {/* 3rd line */}
          <div className="import_third_line">
            {/* Supplier Name */}
            <div className="supplier_name_import">
              <p>Supplier Name </p>
              <Select
                className="select_name_1"
                onChange={(selectedOption) => {
                  if (quantityinmt) {
                    if (selectedOption.value == "") {
                      alert("Kindly Supply Proper Supplier Name");
                    } else {
                      handleSupplierName(selectedOption.value);
                    }
                  } else {
                    alert("Kindly enter the quantity to proceed");
                  }
                }}
                value={
                  supplierName
                    ? { label: supplierName, value: supplierName }
                    : { label: "", value: "" }
                }
                options={[
                  { value: "", label: "" },
                  ...listOfSuppliers.map((item) => ({
                    value: item.VENDNAME,
                    label: item.VENDNAME,
                  })),
                ]}
                isDisabled={hist == true}
              />
            </div>
            {/* Vessel Name */}
            <div className="vessel_name">
              <p>Vessel Name </p>
              <input
                className="input_cost1"
                value={vesselName}
                onChange={(e) => {
                  setVesselName(e.target.value);
                }}
                disabled={hist == true}
              ></input>
            </div>

            <div className="cost_item">
              <p>Cost Item </p>
              <Select
                className="select_name_1"
                onChange={(selectedOption) => {
                  if (quantityinmt) {
                    if (selectedOption.value == "") {
                      alert("Kindly Supply Proper Cost Item");
                    } else {
                      if (
                        selectedOption.value == "Import Duty" ||
                        selectedOption.value == "Marine Cover"
                      ) {
                        if (wheatcost) {
                          handleOptionChange(selectedOption);
                        } else {
                          alert("Kindly Supply Wheat Cost!!");
                        }
                      } else {
                        handleOptionChange(selectedOption);
                      }
                    }
                  } else {
                    alert("Kindly enter the quantity to proceed");
                  }
                }}
                value={
                  selcostitem
                    ? { label: selcostitem, value: selcostitem }
                    : { label: "", value: "" }
                }
                options={[
                  { value: "", label: "" },
                  ...costsheetItem.map((item) => ({
                    value: item.NONSTOCKITEM,
                    label: item.NONSTOCKITEM,
                  })),
                ]}
              />
            </div>
            <div className="costmt">
              <p>Cost/MT </p>
              <input
                className="input_cost_costmt"
                value={
                  costpermt
                    ? costpermt.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""
                }
                onChange={(e) => {
                  setcostpermt(e.target.value);
                  handleCharge(e.target.value);
                }}
              ></input>
            </div>
          </div>
          {/* 4th line */}
          <div className="import_fourth_line">
            <div className="amt">
              <p>Amount in Local Currrency</p>
              <input
                className="checkbos__import__Costing"
                type="checkbox"
                onChange={(e) => {
                  if (selcostitem) {
                    if (e.target.checked) {
                      handleamtlclcurr(1);
                    } else {
                      handleamtlclcurr(0);
                    }
                  } else {
                    alert("Kindly Select the Cost Item first");
                    e.target.checked = false;
                  }
                }}
              />
            </div>
            <div className="vat">
              <p>VAT</p>
              <input
                className="checkbos__import__Costing"
                type="checkbox"
                onChange={(e) => {
                  if (selcostitem) {
                    if (e.target.checked) {
                      handlevat(1);
                    } else {
                      handlevat(0);
                    }
                  } else {
                    alert("Kindly Select the Cost Item first");
                    e.target.checked = false;
                  }
                }}
              ></input>
            </div>
            <div className="amt_usd">
              <p>Amount in USD </p>
              <input
                className="input_cost1"
                value={
                  amountinUSD
                    ? amountinUSD.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""
                }
                readOnly
              ></input>
            </div>
            <div className="exc_rate">
              <p>Exch Rate </p>
              <input
                type="number"
                className="input_cost"
                onChange={(e) => {
                  if (!selcostitem) {
                    alert("Kindly Supply the cost Item first");
                    setamountinKSH("");
                  } else {
                    if (Number(e.target.value <= 0)) {
                      alert("Kindly Provide a Positive Exchange Rate");
                      setexchangerate(1);
                    } else {
                      setexchangerate(e.target.value);
                      setamountinKSH(
                        Number(e.target.value) * Number(amountinUSD)
                      );
                      setamountinclvat(
                        Number(e.target.value) * Number(amountinUSD)
                      );
                    }
                  }
                }}
                value={exchangerate != null ? exchangerate : ""}
              ></input>
            </div>
            <div className="amt_ksh">
              <p>Amount in KSH </p>
              <input
                className="input_cost"
                value={
                  amountinKSH
                    ? amountinKSH.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""
                }
                readOnly
              ></input>
            </div>
          </div>
          {/* 5th line */}
          <div className="import_fifth_line">
            <div className="narr">
              <p style={{ marginBottom: "22px" }}>Narration </p>
              <textarea
                className="text"
                value={narration}
                onChange={(e) => {
                  setNarration(e.target.value);
                }}
              ></textarea>
            </div>
            <div className="import_btn">
              <button
                className="Add"
                onClick={() => {
                  if (selcostingno) {
                    if (flag1 === 1) {
                      handleModify();
                      setflag1(0);
                    } else {
                      addnewCostItem();
                    }
                  } else {
                    AddCostItem();
                  }
                }}
              >
                Add
              </button>
              <button
                className="Remove"
                onClick={() => {
                  if (selcostingno) {
                    if (updateddata.length > 0 || historicdata.length > 0) {
                      RemoveRawMaterialsprod();
                    } else {
                      alert("first add data to remove");
                    }
                  } else {
                    if (tabledata.length > 0) {
                      RemoveRawMaterials();
                    } else {
                      alert("First Add data to remove");
                    }
                  }
                }}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
        {/* 6th line */}
        <div className="import">
          <p>IMPORT CHARGES</p>
        </div>
        {ShowTable && (
          <div className="visible">
            <table className="table_1">
              <thead>
                <tr className="table-header">
                  <td>DESCRIPTION</td>
                  <td>QUANTITY</td>
                  <td>COST PER MT</td>
                  <td>AMOUNT IN USD</td>
                  <td>EXCH RATE</td>
                  <td>AMOUNT EXCL VAT</td>
                  <td>AMOUNT INCL VAT</td>
                  <td>NARRATION</td>
                </tr>
              </thead>
              <tbody>
                {updateddata.length > 0
                  ? updateddata.map((value, index) => {
                      return (
                        <tr
                          onClick={() => {
                            handleRowClick(value, index);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <td>
                            {value.OT_COSTING_SHEET_CHARGE_CODE
                              ? value.OT_COSTING_SHEET_CHARGE_CODE
                              : "N/A"}
                          </td>
                          <td
                            style={{ textAlign: "right", paddingRight: "46px" }}
                          >
                            {value.OT_COSTING_SHEET_QTY
                              ? value.OT_COSTING_SHEET_QTY.toLocaleString(
                                  undefined,
                                  {
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : 0.0}
                          </td>
                          <td
                            style={{ textAlign: "right", paddingRight: "40px" }}
                          >
                            {value.OT_COSTING_SHEET_VAT_COST_PER_UNIT
                              ? value.OT_COSTING_SHEET_VAT_COST_PER_UNIT.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : 0.0}
                          </td>
                          <td
                            style={{ textAlign: "right", paddingRight: "52px" }}
                          >
                            {value.OT_COSTING_SHEET_CHARGE_RATE_USD
                              ? value.OT_COSTING_SHEET_CHARGE_RATE_USD.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : 0.0}
                          </td>
                          <td
                            style={{ textAlign: "right", paddingRight: "42px" }}
                          >
                            {value.OT_COSTING_SHEET_CHARGE_EXCH_RATE
                              ? value.OT_COSTING_SHEET_CHARGE_EXCH_RATE.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : 0.0}
                          </td>
                          <td
                            style={{ textAlign: "right", paddingRight: "52px" }}
                          >
                            {value.OT_COSTING_SHEET_COST_EXC_VAT_KSH
                              ? value.OT_COSTING_SHEET_COST_EXC_VAT_KSH.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : 0.0}
                          </td>
                          <td
                            style={{ textAlign: "right", paddingRight: "42px" }}
                          >
                            {value.OT_COSTING_SHEET_CHARGE_COST_TOTAL
                              ? value.OT_COSTING_SHEET_CHARGE_COST_TOTAL.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : 0.0}
                          </td>
                          <td>
                            {value.OT_COSTING_SHEET_DESCRIPTION
                              ? value.OT_COSTING_SHEET_DESCRIPTION
                              : "N/A"}
                          </td>
                        </tr>
                      );
                    })
                  : historicdata.length > 0
                  ? historicdata.map((value, index) => {
                      return (
                        <tr
                          onClick={() => {
                            handleRowClick(value, index);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <td>
                            {value.OT_COSTING_SHEET_CHARGE_CODE
                              ? value.OT_COSTING_SHEET_CHARGE_CODE
                              : "N/A"}
                          </td>
                          <td
                            style={{ textAlign: "right", paddingRight: "46px" }}
                          >
                            {value.OT_COSTING_SHEET_QTY
                              ? value.OT_COSTING_SHEET_QTY.toLocaleString(
                                  undefined,
                                  {
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : 0.0}
                          </td>
                          <td
                            style={{ textAlign: "right", paddingRight: "40px" }}
                          >
                            {value.OT_COSTING_SHEET_VAT_COST_PER_UNIT
                              ? value.OT_COSTING_SHEET_VAT_COST_PER_UNIT.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : 0.0}
                          </td>
                          <td
                            style={{ textAlign: "right", paddingRight: "52px" }}
                          >
                            {value.OT_COSTING_SHEET_CHARGE_RATE_USD
                              ? value.OT_COSTING_SHEET_CHARGE_RATE_USD.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : 0.0}
                          </td>
                          <td
                            style={{ textAlign: "right", paddingRight: "42px" }}
                          >
                            {value.OT_COSTING_SHEET_CHARGE_EXCH_RATE
                              ? value.OT_COSTING_SHEET_CHARGE_EXCH_RATE.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : 0.0}
                          </td>
                          <td
                            style={{ textAlign: "right", paddingRight: "52px" }}
                          >
                            {value.OT_COSTING_SHEET_COST_EXC_VAT_KSH
                              ? value.OT_COSTING_SHEET_COST_EXC_VAT_KSH.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : 0.0}
                          </td>
                          <td
                            style={{ textAlign: "right", paddingRight: "42px" }}
                          >
                            {value.OT_COSTING_SHEET_CHARGE_COST_TOTAL
                              ? value.OT_COSTING_SHEET_CHARGE_COST_TOTAL.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : 0.0}
                          </td>
                          <td>
                            {value.OT_COSTING_SHEET_DESCRIPTION
                              ? value.OT_COSTING_SHEET_DESCRIPTION
                              : "N/A"}
                          </td>
                        </tr>
                      );
                    })
                  : tabledata.map((value, index) => (
                      <tr
                        onClick={() => {
                          handleRowClickk(value, index);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <td>{value.description ? value.description : "N/A"}</td>
                        <td
                          style={{ textAlign: "right", paddingRight: "46px" }}
                        >
                          {value.quanity
                            ? value.quanity.toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                              })
                            : 0.0}
                        </td>
                        <td
                          style={{ textAlign: "right", paddingRight: "40px" }}
                        >
                          {value.cost
                            ? value.cost.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : 0.0}
                        </td>
                        <td
                          style={{ textAlign: "right", paddingRight: "52px" }}
                        >
                          {value.amountUSD
                            ? value.amountUSD.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : 0.0}
                        </td>
                        <td
                          style={{ textAlign: "right", paddingRight: "42px" }}
                        >
                          {value.exch
                            ? value.exch.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : 0.0}
                        </td>
                        <td
                          style={{ textAlign: "right", paddingRight: "52px" }}
                        >
                          {value.amountExclVAT
                            ? value.amountExclVAT.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : 0.0}
                        </td>
                        <td
                          style={{ textAlign: "right", paddingRight: "42px" }}
                        >
                          {value.amountInclVAT
                            ? value.amountInclVAT.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : 0.0}
                        </td>
                        <td>{value.narration ? value.narration : "N/A"}</td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        )}
        {/* Other Charges Section */}
        <div className="sec__iport__changes">
          <div className="other_charges1" style={{ padding: "10px" }}>
            <div className="other-charge">
              <p>Other Charges</p>
              <Select
                className="pro_select_1"
                onChange={(selectedOption) => {
                  handleOptionChangeOther(selectedOption);
                }}
                value={
                  selothercharges
                    ? { label: selothercharges, value: selothercharges }
                    : { label: "", value: "" }
                }
                options={[
                  { value: "", label: "" },
                  ...othercharges.map((item) => ({
                    value: item.NONSTOCKITEM,
                    label: item.NONSTOCKITEM,
                  })),
                ]}
              />
              <input
                className="cost_input"
                value={otherchargescost}
                onChange={(e) => {
                  setOtherChargesCost(e.target.value);
                }}
              ></input>
            </div>
            <div className="other_1">
              <div
                style={{
                  color: "brown",
                  fontSize: "18px",
                  margin: "10px",
                }}
              >
                <p>OTHER CHARGES</p>
              </div>
              <div style={{ display: "flex", gap: "10px", margin: "10px" }}>
                <button
                  className="Add"
                  onClick={() => {
                    if (selcostingno) {
                      if (flag2 === 1) {
                        handleModify1();
                        setflag2(0);
                      } else {
                        addnewOtherCharges();
                      }
                    } else {
                      AddOtherCharges();
                    }
                  }}
                >
                  Add
                </button>
                <button
                  className="Remove"
                  onClick={() => {
                    if (selcostingno) {
                      if (updateddata1.length > 0 || historicdata1.length > 0) {
                        RemoveOtherChargesProd();
                      } else {
                        alert("First Add Other Charges to remove");
                      }
                    } else {
                      if (tabledata1.length > 0) {
                        RemoveOtherCharges();
                      } else {
                        alert("First Add Other Charges to remove");
                      }
                    }
                  }}
                >
                  Remove
                </button>
              </div>
            </div>
            {ShowTable1 && (
              <div className="visible">
                <table
                  className="table_1 "
                  style={{ width: "90%", marginLeft: "20px" }}
                >
                  <thead>
                    <tr className="table-header">
                      <td>OTHER CHARGE NAME</td>
                      <td
                        style={{
                          textAlign: "right",
                          paddingRight: "100px",
                        }}
                      >
                        OTHER CHARGE COST
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {updateddata1.length > 0
                      ? updateddata1.map((value, index) => {
                          return (
                            <tr
                              onClick={() => {
                                handleOtherChargesRow(value, index);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <td style={{}}>
                                {value.OTHERCHARGE ? value.OTHERCHARGE : "N/A"}
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  paddingRight: "112px",
                                }}
                              >
                                {value.OTHERCHARGEAMOUNT
                                  ? numberWithCommas(value.OTHERCHARGEAMOUNT)
                                  : 0.0}
                              </td>
                            </tr>
                          );
                        })
                      : historicdata1.length > 0
                      ? historicdata1.map((value, index) => {
                          return (
                            <tr
                              onClick={() => {
                                handleOtherChargesRow(value, index);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <td>
                                {value.OTHERCHARGE ? value.OTHERCHARGE : "N/A"}
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  paddingRight: "112px",
                                }}
                              >
                                {value.OTHERCHARGEAMOUNT
                                  ? numberWithCommas(value.OTHERCHARGEAMOUNT)
                                  : 0.0}
                              </td>
                            </tr>
                          );
                        })
                      : tabledata1.map((value, index) => (
                          <tr
                            onClick={() => {
                              handleOtherChargesRow1(value, index);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <td style={{}}>
                              {value.description ? value.description : "N/A"}
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                                paddingRight: "52px",
                              }}
                            >
                              {value.cost
                                ? value.cost.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : 0.0}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            )}
            <div className="cost_2">
              <p>Total Cost</p>
              <input
                className="input_cost_2"
                value={
                  finalothercharges1 > 0
                    ? finalothercharges1.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : finalother > 0
                    ? finalother.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : finalothercharges > 0
                    ? finalothercharges.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""
                }
                readOnly
              ></input>
            </div>
          </div>
          {/* Right Side Calculations */}
          <div className="cost_exc">
            <div className="cost_vat">
              <p>Costs Exclusive VAT</p>
              <p>Costs Inclusive VAT</p>
            </div>
            <div className="total_cost_exc">
              <p>Total Cost</p>
              <input
                className="total_cost_input "
                value={
                  updatedexcl > 0
                    ? updatedexcl.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : totalcostexclvat > 0
                    ? totalcostexclvat.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""
                }
                readOnly
              ></input>
              <input
                className="total_cost_input mt"
                value={
                  updatedincl > 0
                    ? updatedincl.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : totalcostinclvat > 0
                    ? totalcostinclvat.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""
                }
                readOnly
              ></input>
            </div>
            <div className="total_cost_exc_1">
              <p>Cost Per MT</p>
              <input
                className="total_cost_input"
                value={
                  costpermtexclvat ? numberWithCommas(costpermtexclvat) : ""
                }
              ></input>
              <input
                className="total_cost_input mt"
                value={
                  costpermtinclvat ? numberWithCommas(costpermtinclvat) : ""
                }
              ></input>
            </div>
            <div className="total_cost_exc_2">
              <p>Cost Per Kilo</p>
              <input
                className="total_cost_input"
                value={
                  costperkiloexclvat ? numberWithCommas(costperkiloexclvat) : ""
                }
              ></input>
              <input
                className="total_cost_input mt"
                value={
                  costperkiloinclvat ? numberWithCommas(costperkiloinclvat) : ""
                }
              ></input>
            </div>
            <div className="total_cost_exc_3">
              <p>Cost Per 90 Kgs</p>
              <input
                className="total_cost_input"
                value={
                  costper90kgsexclvat
                    ? numberWithCommas(costper90kgsexclvat)
                    : ""
                }
              ></input>
              <input
                className="total_cost_input mt"
                value={
                  costper90kgsinclvat
                    ? numberWithCommas(costper90kgsinclvat)
                    : ""
                }
              ></input>
            </div>
            <div className="derived">
              <p>Derived charges Inclusive of Other charges</p>
            </div>
            <div>
              <div className="cost_vat">
                <p>Costs Exclusive VAT</p>
                <p>Costs Inclusive VAT</p>
              </div>
              <div className="total_cost_exc">
                <p>Total Cost</p>
                <input
                  className="total_cost_input"
                  value={
                    updatedexcl > 0 && finalother > 0
                      ? (updatedexcl + finalother).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : updatedexcl > 0 && finalother === 0
                      ? (updatedexcl + finalothercharges1).toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )
                      : updatedexcl === 0 && finalother > 0
                      ? (totalcostexclvat1 + finalother).toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )
                      : totalcostexclvat1 > 0 && Number(finalothercharges1) > 0
                      ? (totalcostexclvat1 + finalothercharges1).toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )
                      : totalcostexclvat1 > 0 && Number(finalothercharges1) <= 0
                      ? 0.0
                      : totalcostexclvatother.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                  }
                ></input>
                <input
                  className="total_cost_input mt"
                  value={
                    updatedincl > 0 && finalother > 0
                      ? (updatedincl + finalother).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : updatedincl > 0 && finalother === 0
                      ? (updatedincl + finalothercharges1).toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )
                      : updatedincl === 0 && finalother > 0
                      ? (totalcostinclvat1 + finalother).toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )
                      : totalcostinclvat1 && Number(finalothercharges1) > 0
                      ? (totalcostinclvat1 + finalothercharges1).toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )
                      : totalcostinclvat1 && Number(finalothercharges1) <= 0
                      ? 0.0
                      : totalcostinclvatother.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                  }
                ></input>
              </div>
              <div className="total_cost_exc_1">
                <p>Cost Per MT</p>
                <input
                  className="total_cost_input"
                  value={
                    costpermtexclvatother
                      ? numberWithCommas(costpermtexclvatother)
                      : ""
                  }
                ></input>
                <input
                  className="total_cost_input mt"
                  value={
                    costpermtinclvatother
                      ? numberWithCommas(costpermtinclvatother)
                      : ""
                  }
                ></input>
              </div>
              <div className="total_cost_exc_2">
                <p>Cost Per Kilo</p>
                <input
                  className="total_cost_input "
                  value={
                    costperkiloexclvatother
                      ? numberWithCommas(costperkiloexclvatother)
                      : ""
                  }
                ></input>
                <input
                  className="total_cost_input mt"
                  value={
                    costperkiloinclvatother
                      ? numberWithCommas(costperkiloinclvatother)
                      : ""
                  }
                ></input>
              </div>
              <div className="total_cost_exc_3">
                <p>Cost Per 90 Kgs</p>
                <input
                  className="total_cost_input"
                  value={
                    costper90kgsexclvatother
                      ? numberWithCommas(costper90kgsexclvatother)
                      : ""
                  }
                ></input>
                <input
                  className="total_cost_input mt"
                  value={
                    costper90kgsinclvatother
                      ? numberWithCommas(costper90kgsinclvatother)
                      : ""
                  }
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="toSHow__when__Print">
        <main style={{ fontFamily: "Times New Roman, Times, serif" }}>
          <h1>ESTIMATED COSTING FOR IMPORTED WHEAT</h1>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <div>
              <span>
                <b>ITEM NAME : </b>
              </span>
              <span>{selitemname}</span>
            </div>
            <div style={{ marginRight: "30px" }}>
              <span>
                <b>DATE : </b>
              </span>
              <span>{moment(date).format("DD/MM/YYYY")}</span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <div>
              <span>
                <b>VESSEL NAME : </b>
              </span>
              <span>{vesselName}</span>
            </div>
            <div>
              <span>
                <b>SUPPLIER NAME : </b>
              </span>
              <span>{supplierName}</span>
            </div>
          </div>

          <table class="table-print">
            <thead>
              <tr>
                <td>DESCRIPTION</td>
                <td>QUANTITY</td>
                <td>COST PER MT</td>
                <td>AMOUNT IN USD</td>
                <td>EXCH RATE</td>
                <td>AMOUNT EXCL VAT</td>
                <td>AMOUNT INCL VAT</td>
              </tr>
            </thead>
            <tbody className="print__body">
              {updateddata.length > 0
                ? updateddata.map((value, index) => {
                    return (
                      <tr
                        key={index}
                        onClick={() => {
                          handleRowClick(value, index);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <td>
                          {value.OT_COSTING_SHEET_CHARGE_CODE
                            ? value.OT_COSTING_SHEET_CHARGE_CODE
                            : "N/A"}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {value.OT_COSTING_SHEET_QTY
                            ? value.OT_COSTING_SHEET_QTY.toLocaleString(
                                undefined,
                                {
                                  maximumFractionDigits: 2,
                                }
                              )
                            : 0.0}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {value.OT_COSTING_SHEET_VAT_COST_PER_UNIT
                            ? value.OT_COSTING_SHEET_VAT_COST_PER_UNIT.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : 0.0}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {value.OT_COSTING_SHEET_CHARGE_RATE_USD
                            ? value.OT_COSTING_SHEET_CHARGE_RATE_USD.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : 0.0}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {value.OT_COSTING_SHEET_CHARGE_EXCH_RATE
                            ? value.OT_COSTING_SHEET_CHARGE_EXCH_RATE.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : 0.0}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {value.OT_COSTING_SHEET_COST_EXC_VAT_KSH
                            ? value.OT_COSTING_SHEET_COST_EXC_VAT_KSH.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : 0.0}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {value.OT_COSTING_SHEET_CHARGE_COST_TOTAL
                            ? value.OT_COSTING_SHEET_CHARGE_COST_TOTAL.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : 0.0}
                        </td>
                      </tr>
                    );
                  })
                : historicdata.length > 0
                ? historicdata.map((value, index) => {
                    return (
                      <tr
                        onClick={() => {
                          handleRowClick(value, index);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <td>
                          {value.OT_COSTING_SHEET_CHARGE_CODE
                            ? value.OT_COSTING_SHEET_CHARGE_CODE
                            : "N/A"}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {value.OT_COSTING_SHEET_QTY
                            ? value.OT_COSTING_SHEET_QTY.toLocaleString(
                                undefined,
                                {
                                  maximumFractionDigits: 2,
                                }
                              )
                            : 0.0}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {value.OT_COSTING_SHEET_VAT_COST_PER_UNIT
                            ? value.OT_COSTING_SHEET_VAT_COST_PER_UNIT.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : 0.0}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {value.OT_COSTING_SHEET_CHARGE_RATE_USD
                            ? value.OT_COSTING_SHEET_CHARGE_RATE_USD.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : 0.0}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {value.OT_COSTING_SHEET_CHARGE_EXCH_RATE
                            ? value.OT_COSTING_SHEET_CHARGE_EXCH_RATE.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : 0.0}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {value.OT_COSTING_SHEET_COST_EXC_VAT_KSH
                            ? value.OT_COSTING_SHEET_COST_EXC_VAT_KSH.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : 0.0}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {value.OT_COSTING_SHEET_CHARGE_COST_TOTAL
                            ? value.OT_COSTING_SHEET_CHARGE_COST_TOTAL.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : 0.0}
                        </td>
                      </tr>
                    );
                  })
                : tabledata.map((value, index) => (
                    <tr
                      onClick={() => {
                        handleRowClickk(value, index);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{value.description ? value.description : "N/A"}</td>
                      <td style={{ textAlign: "right" }}>
                        {value.quanity
                          ? value.quanity.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })
                          : 0.0}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {value.cost
                          ? value.cost.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : 0.0}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {value.amountUSD
                          ? value.amountUSD.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : 0.0}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {value.exch
                          ? value.exch.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : 0.0}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {value.amountExclVAT
                          ? value.amountExclVAT.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : 0.0}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {value.amountInclVAT
                          ? value.amountInclVAT.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : 0.0}
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
              fontSize: "16px",
              fontWeight: "bold",
              gap: "10px",
            }}
          >
            {/* <span>Total Amount Excl VAT : </span> */}
            <span>
              {updatedexcl > 0
                ? updatedexcl.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : totalcostexclvat > 0
                ? totalcostexclvat.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : ""}
            </span>
            <span style={{ marginRight: "13px" }}>|</span>
            {/* <span>Total Amount Incl VAT : </span> */}
            <span>
              {updatedincl > 0
                ? updatedincl.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : totalcostinclvat > 0
                ? totalcostinclvat.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : ""}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div
              className="total_cost_exc"
              style={{
                justifyContent: "flex-end",
                marginRight: "5px",
                fontSize: "18px",
              }}
            >
              <p>
                <b>Total Cost</b>
              </p>
              {/* <input
                style={{ textAlign: "right" }}
                className="total_cost_input "
                value={
                  updatedexcl > 0
                    ? updatedexcl.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : totalcostexclvat > 0
                    ? totalcostexclvat.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""
                }
                readOnly
              ></input> */}
              <input
                style={{
                  textAlign: "right",
                  fontSize: "18px",
                  fontWeight: "bold",
                  backgroundColor: "lightgray",
                }}
                className="total_cost_input mt"
                value={
                  updatedincl > 0
                    ? updatedincl.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : totalcostinclvat > 0
                    ? totalcostinclvat.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""
                }
                readOnly
              ></input>
            </div>
            <div
              className="total_cost_exc_1"
              style={{
                justifyContent: "flex-end",
                marginRight: "5px",
                fontSize: "18px",
              }}
            >
              <p>
                <b>Cost Per MT</b>
              </p>
              {/* <input
                style={{ textAlign: "right" }}
                className="total_cost_input"
                value={
                  costpermtexclvat ? numberWithCommas(costpermtexclvat) : ""
                }
              ></input> */}
              <input
                style={{
                  textAlign: "right",
                  fontSize: "18px",
                  fontWeight: "bold",
                  backgroundColor: "lightgray",
                }}
                className="total_cost_input mt"
                value={
                  costpermtinclvat ? numberWithCommas(costpermtinclvat) : ""
                }
              ></input>
            </div>
            <div
              className="total_cost_exc_2"
              style={{
                justifyContent: "flex-end",
                marginRight: "5px",
                fontSize: "18px",
              }}
            >
              <p>
                <b>Cost Per Kilo</b>
              </p>
              {/* <input
                style={{ textAlign: "right" }}
                className="total_cost_input"
                value={
                  costperkiloexclvat ? numberWithCommas(costperkiloexclvat) : ""
                }
              ></input> */}
              <input
                style={{
                  textAlign: "right",
                  fontSize: "18px",
                  fontWeight: "bold",
                  backgroundColor: "lightgray",
                }}
                className="total_cost_input mt"
                value={
                  costperkiloinclvat ? numberWithCommas(costperkiloinclvat) : ""
                }
              ></input>
            </div>
            <div
              className="total_cost_exc_3"
              style={{
                justifyContent: "flex-end",
                marginRight: "5px",
                fontSize: "18px",
              }}
            >
              <p>
                <b>Cost Per 90 Kgs</b>
              </p>
              {/* <input
                style={{ textAlign: "right" }}
                className="total_cost_input"
                value={
                  costper90kgsexclvat
                    ? numberWithCommas(costper90kgsexclvat)
                    : ""
                }
              ></input> */}
              <input
                style={{
                  textAlign: "right",
                  fontSize: "18px",
                  fontWeight: "bold",
                  backgroundColor: "lightgray",
                }}
                className="total_cost_input mt"
                value={
                  costper90kgsinclvat
                    ? numberWithCommas(costper90kgsinclvat)
                    : ""
                }
              ></input>
            </div>
          </div>
        </main>
      </section>
    </>
  );
};

export default ImportCosting;
